import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./authentication.css";

// mui@material
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import Link from '@mui/material/Link';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LinearProgress } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme({
  typography: {
    fontFamily: ["ITC Avant Garde Gothic W05 X Lt"].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              backgroundColor: "#015a80",
              color: "#fff",
            }),
        }),
      },
    },
  },
});

export default function ResetPassword() {
  const location = useLocation();
  const email = location.state;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sucessMessage, setSucessMessage] = useState("");
  const [isDesktop, setDesktop] = useState(window.innerWidth > 598);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 598);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  useEffect(() => {
    document.title = "Reset Password";
  }, []);
  const navigate = useNavigate();

  let publicUrl = process.env.PUBLIC_URL + "/";

  const initialValues = {
    new_password: "",
    confirm_password: "",
  };
  const ValidationSchema = Yup.object().shape({
    new_password: Yup.string()
      .required("password is required*")
      .min(8, "password is too short - should be 8 characters minimum.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
    confirm_password: Yup.string()
      .required("confirm password is required*")
      .oneOf([Yup.ref("new_password"), null], "passwords must match"),
  });

  const onsubmit = (values, props) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/forgetpass/reset`,
        {
          email: email,
          new_password: values.new_password,
          new_confirm_password: values.confirm_password,
        },
        {
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
        props.setSubmitting(false);
        setSucessMessage("password successfully changed !");
        setTimeout(() => {
          setSucessMessage("");
          navigate("/signin");
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        props.setSubmitting(false);
        setErrorMessage("something went wrong");
      });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundColor: "#0f2c4e",
            backgroundImage: `url(${publicUrl + "/assets/img/logo1.png"})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        />

        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 2,
            }}
          >
            {isDesktop ? (
              <Avatar sx={{ m: 1, bgcolor: "#015a80" }}>
                <LockIcon />
              </Avatar>
            ) : (
              <img
                src={publicUrl + "/assets/img/logo1.png"}
                width={100}
                height={100}
              />
            )}

            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            {/* component="form" noValidate onSubmit={handleSubmit} */}
            <Box sx={{ mt: 1 }}>
              {errorMessage ? (
                <Alert variant="outlined" severity="error">
                  {errorMessage}
                </Alert>
              ) : (
                ""
              )}
              {sucessMessage ? (
                <Alert variant="outlined" severity="success">
                  {sucessMessage}
                </Alert>
              ) : (
                ""
              )}
              <Typography
                component="span"
                variant="h8"
                sx={{
                  color: "gray",
                  fontSize: 13,
                }}
              >
                Your new password must be different form previously used
                password
              </Typography>
              <Formik
                initialValues={initialValues}
                validateOnBlur={false}
                onSubmit={onsubmit}
                validationSchema={ValidationSchema}
              >
                {(props) => (
                  <Form>
                    <Field
                      as={TextField}
                      margin="normal"
                      required
                      fullWidth
                      name="new_password"
                      label="New Password"
                      type={showPassword ? "text" : "password"}
                      id="new_password"
                      autoComplete="current-password"
                      helperText={<ErrorMessage name="new_password" />}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Field
                      as={TextField}
                      margin="normal"
                      required
                      fullWidth
                      name="confirm_password"
                      label="Confirm Password"
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirm_password"
                      autoComplete="confirm_password"
                      helperText={<ErrorMessage name="confirm_password" />}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={props.isSubmitting}
                      sx={{
                        mt: 3,
                        mb: 2,
                        ":hover": {
                          bgcolor: "#0f2c4e", // "primary.main" theme.palette.primary.main
                          color: "white",
                        },
                      }}
                    >
                      Set New Password
                    </Button>
                    {props.isSubmitting ? (
                      <LinearProgress
                        sx={{
                          mt: 1,
                          mb: 2,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Form>
                )}
              </Formik>

              <Grid container>
                <Grid item xs></Grid>
                <Grid item>
                  <Link
                    to="/signup"
                    style={{ textDecoration: "underline", color: "#0077c0" }}
                  >
                    {"<  Back to Sign In"}
                  </Link>
                  {/* <Link href="/signin" variant="body2" sx={{
                                        ':hover': {
                                            color: '#0f2c4e',
                                        },
                                    }}>
                                        {"<  Back to Sign In"}
                                    </Link> */}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
