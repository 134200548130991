import { createContext, useState, useContext, useMemo } from "react";

// import { IMenuItem } from '../types';
import { MENU_LIST } from "../constants/menu";
import { MENU_LIST as MENU_LIST_AGENCY } from "../constants/agencyMenu";
import { MENU_LIST as MENU_LIST_INVESTOR } from "../constants/investorMenu";
import { MENU_LIST as MENU_LIST_CLIENT } from "../constants/clientMenu";
import { Menu } from "react-pro-sidebar";
import { jwtDecode } from "jwt-decode";
// type DrawerContextType = {
//   isOpened;
//   toggleIsOpened: (value) => void;
//   menu;
// };

// type DrawerContextProviderProps = {
//   children: React.ReactNode;
// };

const DrawerContext = createContext(undefined);

export const DrawerContextProvider = ({ children }) => {
  const [isOpened, toggleIsOpened] = useState(false);
  const token = localStorage.getItem("access_token");
  const decoded = jwtDecode(token);
  const role = decoded.role;
  const value = useMemo(
    () => ({
      isOpened,
      toggleIsOpened,
      menu:
        role == "agency"
          ? MENU_LIST_AGENCY
          : role == "investor"
          ? MENU_LIST_INVESTOR
          : MENU_LIST_CLIENT,
    }),
    [isOpened]
  );

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
};

export const useDrawerContext = () => {
  const context = useContext(DrawerContext);
  if (context === undefined) {
    throw new Error(
      "useDrawerContext must be used within a DrawerContextProvider"
    );
  }
  return context;
};
