import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import { Formik } from 'formik';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { Navigate } from "react-router-dom";
class Registration extends Component {
  state = { user: null};
  render() {
    let { user } = this.state;
    // let history = useHistory();
    let publicUrl = process.env.PUBLIC_URL + '/api/login'
    let imagealt = 'image'
    let data = sectiondata.whychooseus
    const url="http://localhost:8000/api/login"

 function submitLogin (values,{setSubmitting}){
  
      // e.preventDefault();
   axios.post(url ,
   {
    email: values.email,
    password: values.password
  },
  {
    headers: {
        "Accept": "application/json",
        "content-type": "application/json"
    }
  }
   
   ).then(res => {
setSubmitting(false)
    localStorage.setItem("access_token", res.data.token);
    localStorage.setItem("name",res.data.name);
    localStorage.setItem('role',res.data.role);
    // let navigate = useNavigate();
    // navigate('/add-property')
    this.setState(user);
 
    if(res){
 
    }
  
  })
  .catch(err => {
    setSubmitting(false)
    console.log(err);
  });
   }
function submitRegister(values,{setSubmitting}){
  axios.post("http://localhost:8000/api/register" ,
    {
     email: values.email,
     password: values.password,
     confirm_password:values.confirm_password
   },
   {
     headers: {
         "Accept": "application/json",
         "content-type": "application/json"
     }
   }
    
    ).then(res => {
 setSubmitting(false)
console.log(res)
   
   })
   .catch(err => {
     setSubmitting(false)
     console.log(err);
   });
}   

    return <div className="register-page-area pd-bottom-100">
      <div className="container">
        <div className="row justify-content-center">
          {/******* Login Form *******/}
          <div className="col-xl-4 col-lg-5 col-md-6 mb-5 mb-md-0">
          {user && (
          <Navigate to="/add-property" replace={true} />
        )}
            <Formik
              initialValues={{ email: '', password: '' }}
              validate={values => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'email is required*';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }

                if (!values.password) {
                  errors.password = 'password is required*'
                }


                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
         submitLogin(values,{setSubmitting})

                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >

              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form className="contact-form-wrap contact-form-bg" onSubmit={handleSubmit}>
 
                  <h4>Login</h4>
                  <div className="rld-single-input">
                    <input type="email" placeholder="Entry Email" name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />

                    <span style={{ color: '#A52A2A', fontStyle: 'italic', fontSize: '12px', lineHeight: 'normal' }}> {errors.email && touched.email && errors.email}  </span>
                  </div>
                  <div className="rld-single-input">
                    <input type="password" placeholder="Entry Password" name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password} />
                    <div style={{ color: '#A52A2A', fontStyle: 'italic', fontSize: '12px', lineHeight: 'normal' }}>{errors.password && touched.password && errors.password}</div>
                  </div>
                  <div className="btn-wrap">
                    <button className="btn btn-yellow" type="submit" disabled={isSubmitting}>Sign In</button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
  {/******* Login Form *******/}



  {/******* Register Form *******/}
          <div className="col-xl-4 col-lg-5 col-md-6">
            <Formik
              initialValues={{ email: '', password: '', confirm_password: '' }}
              validate={values => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'email is required*';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                if (!values.password) {
                  errors.password = 'password is required*'
                } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,12}$/i.test(values.password)) {
                  errors.password = 'password must be at least 8 characters long and contain at least one uppercase letter , one lowercase , and one number'
                }
                if (!values.confirm_password) {
                  errors.confirm_password = 're-enter password is required*'
                } else if (values.password && values.confirm_password && values.password != values.confirm_password) {
                  errors.confirm_password = "password does not match"
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
               submitRegister(values,{setSubmitting})
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >

              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form className="contact-form-wrap contact-form-bg" onSubmit={handleSubmit}>
                  <h4>Registration</h4>
                  <div className="rld-single-input">
                    <input type="email" name="email" placeholder="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <div style={{ color: '#A52A2A', fontStyle: 'italic', fontSize: '12px' }}> {errors.email && touched.email && errors.email}</div>
                  </div>


                  <div className="rld-single-input">
                    <input type="password" name="password" placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password} />
                    <div style={{ color: '#A52A2A', fontStyle: 'italic', fontSize: '12px', lineHeight: 'normal' }}>{errors.password && touched.password && errors.password}</div>

                  </div>
                  <div className="rld-single-input">
                    <input type="password" name="confirm_password" placeholder="Re-enter password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirm_password}
                    />
                    <div style={{ color: '#A52A2A', fontStyle: 'italic', fontSize: '12px', lineHeight: 'normal' }}>{errors.confirm_password && touched.confirm_password && errors.confirm_password}</div>
                  </div>
                  <div className="btn-wrap">
                    <button className="btn btn-yellow" type="submit" disabled={isSubmitting}>Register</button>
                  </div>
                  <ul className="social-icon">
                    <li className="ml-0">
                      <a href="#" target="_blank"><i className="fa fa-facebook  " /></a>
                    </li>
                    <li>
                      <a href="#" target="_blank"><i className="fa fa-twitter  " /></a>
                    </li>
                    <li>
                      <a href="#" target="_blank"><i className="fa fa-linkedin" /></a>
                    </li>
                  </ul>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  }
}



export default Registration