import React, { useEffect, useState } from "react";
import Sidebar from "./sidebar";
import Navbar from "./navbar";
import { useLocation } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import Header from "./components/layout/Header";
import { Drawer } from "./components/layout/Drawer";
import Footer from "./footer";
import { DrawerContextProvider } from "./contexts/drawer-context";

function PropertyDetailsDashboard() {
  const location = useLocation();
  const data = location.state;

  return (
    // <div className={isOpen ? "open wrapper" : "wrapper"}>
    //   {isOpen ? <Sidebar /> : ""}
    //   <div className="main">
    //     <Navbar toggleSidebar={toggleSidebar} />

    //     <main className="content">
    //       <div className="container-fluid p-0">
    //         fisrt Name :{data?.name?.firstName}
    //         <br />
    //         last name: {data?.name?.lastName}
    //         <br />
    //         address: {data?.address}
    //         <br />
    //         city: {data?.city}
    //         <br />
    //         {data?.state}
    //         state <br />
    //       </div>
    //     </main>

    //   </div>
    // </div>

    <div>
      <CssBaseline />
      <DrawerContextProvider>
        <div className="wrapper">
          <Drawer />
          <div className="main">
            <Header />
            <main className="content">
              <div className="container-fluid p-0">
                <main className="content">
                  <div className="container-fluid p-0">
                    iD :{data?.id}
                    <br />
                  </div>
                </main>
              </div>
            </main>
            <Footer></Footer>
          </div>
        </div>
      </DrawerContextProvider>
    </div>
  );
}

export default PropertyDetailsDashboard;
