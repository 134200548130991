import * as React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { TasksProgress } from "../task-progress";
import { Budget } from "../budget";
import { LatestOrders } from "../latest-orders";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Stack,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import Select from "react-select";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { useNavigate } from "react-router-dom";
import Box from "@mui/material";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import Typography from "@mui/material/Typography";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Link from "@mui/material/Link";
import MovingOutlinedIcon from "@mui/icons-material/MovingOutlined";
import MovingIcon from "@mui/icons-material/Moving";
import { PieChart } from "@mui/x-charts/PieChart";
const orders = [
  {
    id: 1,
    customer: "nad",
    amount: "200",
    status: "pending",
    createdAt: "14/15/2025",
  },
];
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

//nested data is ok, see accessorKeys in ColumnDef below
const data = [
  {
    name: {
      firstName: "John",
      lastName: "Doe",
    },
    address: "261 Erdman Ford",
    city: "East Daphne",
    state: "Kentucky",
  },
  {
    name: {
      firstName: "Jane",
      lastName: "Doe",
    },
    address: "769 Dominic Grove",
    city: "Columbus",
    state: "Ohio",
  },
  {
    name: {
      firstName: "Joe",
      lastName: "Doe",
    },
    address: "566 Brakus Inlet",
    city: "South Linda",
    state: "West Virginia",
  },
  {
    name: {
      firstName: "Kevin",
      lastName: "Vandy",
    },
    address: "722 Emie Stream",
    city: "Lincoln",
    state: "Nebraska",
  },
  {
    name: {
      firstName: "Joshua",
      lastName: "Rolluffs",
    },
    address: "32188 Larkin Turnpike",
    city: "Charleston",
    state: "South Carolina",
  },
  {
    name: {
      firstName: "Joshua",
      lastName: "Rolluffs",
    },
    address: "32188 Larkin Turnpike",
    city: "Charleston",
    state: "South Carolina",
  },
  {
    name: {
      firstName: "Joshua",
      lastName: "Rolluffs",
    },
    address: "32188 Larkin Turnpike",
    city: "Charleston",
    state: "South Carolina",
  },
  {
    name: {
      firstName: "Joshua",
      lastName: "Rolluffs",
    },
    address: "32188 Larkin Turnpike",
    city: "Charleston",
    state: "South Carolina",
  },
  {
    name: {
      firstName: "Joshua",
      lastName: "Rolluffs",
    },
    address: "32188 Larkin Turnpike",
    city: "Charleston",
    state: "South Carolina",
  },
];

function InvestorDashboard() {
  const navigate = useNavigate();
  const [colorX, setColorX] = useState("None");
  const [colorY, setColorY] = useState("piecewise");
  const [startDate, setStartDate] = useState(new Date());
  const [isFilterBalanceChart, setIsFilterBalanceChart] = useState(true);
  const [isFilterIncomesExpencesChart, setIsFilterIncomesExpensesChart] =
    useState(true);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "name.firstName", //access nested data with dot notation
        header: "First Name",
        size: 150,
      },
      {
        accessorKey: "name.lastName",
        header: "Last Name",
        size: 150,
      },
      {
        accessorKey: "address", //normal accessorKey
        header: "Address",
        size: 200,
      },
      {
        accessorKey: "city",
        header: "City",
        size: 150,
      },
      {
        accessorKey: "state",
        header: "State",
        size: 150,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    initialState: { pagination: { pageSize: 5, pageIndex: 0 } },

    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        console.info(event, row);
        navigate("/property-dashboard-details", { state: row.original });
      },
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),
    muiTableHeadRowProps: () => ({
      sx: {
        // backgroundColor: 'rgba(224, 224, 224, 1)',
        // padding: 100
      },
    }),
    muiTableHeadCellProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example
      sx: {
        fontWeight: "normal",
        fontSize: "12px",
      },
    },
  });
  const dataset = [
    { min: -12, max: -4, precip: 79, month: "Jan" },
    { min: -11, max: -3, precip: 66, month: "Feb" },
    { min: -6, max: 2, precip: 76, month: "Mar" },
    { min: 1, max: 9, precip: 106, month: "Apr" },
    { min: 8, max: 17, precip: 105, month: "Mai" },
    { min: 15, max: 24, precip: 114, month: "Jun" },
    { min: 18, max: 26, precip: 106, month: "Jul" },
    { min: 17, max: 26, precip: 105, month: "Aug" },
    { min: 13, max: 21, precip: 100, month: "Sept" },
    { min: 6, max: 13, precip: 116, month: "Oct" },
    { min: 0, max: 6, precip: 93, month: "Nov" },
    { min: -8, max: -1, precip: 93, month: "Dec" },
  ];

  return (
    <>
      <h4 className="ml-4" style={{ color: "gray" }}>
        {" "}
        Investor Dashboard
      </h4>
      {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}
      {/****** STATICS ******/}
      <div className="d-flex  justify-content-lg-center justify-content-start gap-5 flex-wrap  my-5">
        <div className="d-flex " style={{ flexBasis: "300" }}>
          <Avatar sx={{ backgroundColor: "rgb(46, 150, 255)" }}>
            {/* <CurrencyDollarIcon fontSize="var(--icon-fontSize-lg)" /> */}
            <HomeWorkIcon fontSize="small" />
          </Avatar>
          <div className="mx-2">
            <p className="font-weight-bold" style={{ marginBottom: -8 }}>
              {" "}
              10000
            </p>
            <div>
              <Typography
                color="text.secondary"
                variant="overline"
                sx={{
                  textTransform: "capitalize",
                }}
              >
                Total Invested Properties
              </Typography>
            </div>
          </div>
        </div>
        <div className="d-flex" style={{ flexBasis: "300" }}>
          <Avatar sx={{ backgroundColor: "#02B2AF" }}>
            {/* <CurrencyDollarIcon fontSize="var(--icon-fontSize-lg)" /> */}
            <PersonOutlineIcon />
          </Avatar>
          <div className="mx-2">
            <p className="font-weight-bold" style={{ marginBottom: -8 }}>
              {" "}
              10000
            </p>
            <div>
              <Typography
                color="text.secondary"
                variant="overline"
                sx={{
                  textTransform: "capitalize",
                }}
              >
                TotaL Clients
              </Typography>
            </div>
          </div>
        </div>
        <div className="d-flex " style={{ flexBasis: "300" }}>
          <Avatar sx={{ backgroundColor: "orange" }}>
            {/* <CurrencyDollarIcon fontSize="var(--icon-fontSize-lg)" /> */}
            <HomeWorkIcon />
          </Avatar>
          <div className="mx-2">
            <p className="font-weight-bold" style={{ marginBottom: -8 }}>
              {" "}
              10000
            </p>
            <div>
              <Typography
                color="text.secondary"
                variant="overline"
                sx={{
                  textTransform: "capitalize",
                  marginTop: "-8px",
                }}
              >
                Pending Payments
              </Typography>
            </div>
          </div>
        </div>
        <div className="d-flex " style={{ flexBasis: "300" }}>
          <Avatar sx={{ backgroundColor: "#02B2AF" }}>
            {/* <CurrencyDollarIcon fontSize="var(--icon-fontSize-lg)" /> */}
            <AddHomeWorkIcon />
          </Avatar>
          <div className="mx-2">
            <p className="font-weight-bold" style={{ marginBottom: -8 }}>
              {" "}
              10000
            </p>
            <div>
              <Typography
                color="text.secondary"
                variant="overline"
                sx={{
                  textTransform: "capitalize",
                }}
              >
                Overdue Payments
              </Typography>
            </div>
          </div>
        </div>
      </div>

      {/* ***********INCOME/EXPNSES LINE CHART **************/}
      <div className="my-4">
        {/* <div style={{ height: 100 }} className='my-2'>
                    {isFilterIncomesExpencesChart ?

                        <div className='d-flex justify-content-end '><FilterListIcon onClick={() => {
                            setIsFilterIncomesExpensesChart(!isFilterIncomesExpencesChart)
                        }} style={{ cursor: 'pointer' }} /></div> :

                        <div className='d-flex justify-content-between flex-wrap' style={{ marginLeft: 50 }}>
                            {!isFilterIncomesExpencesChart ?
                                <div className="row"  >
                                    <div className='col-lg-4 col-8  mt-4 p-0'>
                                        <Select options={options} placeholder='property' styles={{
                                            control: (base) => ({
                                                ...base,
                                                border: `1px solid #e5e7eb`,
                                                borderRadius: "0.5rem",
                                                boxShadow: "none",
                                                "&:hover": {
                                                    border: "1px solid none",
                                                },
                                            }),
                                        }} />
                                    </div>

                                    <label className='col-lg-4 col-12'>
                                        <span >Start Date:</span>
                                        <input type="date" name="party" placeholder='start date' className='mx-2 rounded border' style={{ color: '#cccccc', borderColor: '#cccccc', borderTopColor: '#cccccc', padding: 6 }} /></label>
                                    <label className='col-lg-4 col-12  mb-5' >
                                        <span >End  Date :</span>
                                        <input type="date" name="party" placeholder='end date' className='mx-2 rounded border' style={{ color: '#cccccc', borderColor: '#cccccc', borderTopColor: '#cccccc', padding: 6 }} /></label>

                                </div> : ''}


                            <FilterListOffIcon onClick={() => {
                                setIsFilterIncomesExpensesChart(!isFilterIncomesExpencesChart)
                            }} style={{ cursor: 'pointer' }} />
                        </div>


                    }
                </div>

                <div className="d-flex flex-column mt-5" style={{ marginTop: 5, width: '100%', maxWidth: 1000 }} >


                    <LineChart
                        dataset={dataset}
                        xAxis={[{
                            // data: [1, 2, 3, 5, 8, 10],
                            // label: 'month'
                            scaleType: 'band',
                            dataKey: 'month'
                        }]}

                        series={[
                            {
                                data: [2, 5.5, 2, 8.5, 1.5, 5, 2, 5.5, 2, 8.5, 1.5, 5],
                                label: 'incomes'
                            },
                            {
                                data: [4, 9.5, 2, 6.5, 0.5, 3, 2, 5.5, 2, 8.5, 1.5, 5],
                                label: 'expenses'
                            },
                        ]}
                        // width={1000}
                        height={300}
                    />
                </div> */}

        <div>
          <div
            className="d-flex flex-column gap-2 float-left"
            style={{ marginTop: 5, width: "100%", maxWidth: 1000 }}
          >
            <div className="my-2 h-50" style={{ height: 50 }}>
              {isFilterIncomesExpencesChart ? (
                <div className="d-flex justify-content-end ">
                  <FilterListIcon
                    onClick={() => {
                      setIsFilterIncomesExpensesChart(
                        !isFilterIncomesExpencesChart
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ) : (
                <div
                  className="d-flex justify-content-between "
                  style={{ marginLeft: 50 }}
                >
                  {!isFilterIncomesExpencesChart ? (
                    <div className="row justify-content-center">
                      <div className="col-lg-4 col-12 px-1">
                        <Select
                          options={options}
                          placeholder="property"
                          styles={{
                            control: (base) => ({
                              ...base,
                              width: 122,
                              border: `1px solid #e5e7eb`,
                              borderRadius: "0.5rem",
                              boxShadow: "none",
                              "&:hover": {
                                border: "1px solid none",
                              },
                            }),
                          }}
                        />
                      </div>

                      <div className="col-lg-4 col-12 mt-lg-0 mt-2 px-1">
                        <label>
                          <input
                            type="date"
                            name="party"
                            className="mx-lg-2 rounded border"
                            style={{
                              color: "#cccccc",
                              borderColor: "#cccccc",
                              borderTopColor: "#cccccc",
                              padding: 6,
                              width: 122,
                            }}
                          />
                        </label>{" "}
                      </div>
                      <div className="col-lg-4 col-12 px-1">
                        <label>
                          <input
                            type="date"
                            name="party"
                            className="mx-lg-2 rounded border"
                            style={{
                              color: "#cccccc",
                              borderColor: "#cccccc",
                              borderTopColor: "#cccccc",
                              padding: 6,
                              width: 122,
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <FilterListOffIcon
                    onClick={() => {
                      setIsFilterIncomesExpensesChart(
                        !isFilterIncomesExpencesChart
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              )}
            </div>

            <LineChart
              dataset={dataset}
              xAxis={[
                {
                  // data: [1, 2, 3, 5, 8, 10],
                  // label: 'month'
                  scaleType: "band",
                  dataKey: "month",
                },
              ]}
              series={[
                {
                  data: [2, 5.5, 2, 8.5, 1.5, 5, 2, 5.5, 2, 8.5, 1.5, 5],
                  label: "incomes",
                },
                {
                  data: [4, 9.5, 2, 6.5, 0.5, 3, 2, 5.5, 2, 8.5, 1.5, 5],
                  label: "expenses",
                },
              ]}
              // width={500}
              height={300}
            />
          </div>
          {/* <Card className='text-center float-right my-5' sx={{ width: 300 }}>
                        <CardHeader title="Incomes/Expenses" component='h4' sx={{
                            color: 'rgb(46, 150, 255)'
                        }}></CardHeader>
                        <CardContent>
                            <p>
                                <MovingIcon fontSize='large' sx={{
                                    color: '#B9D9EB'
                                }} /></p>
                            <Typography component="p" variant="h4">
                                $3,024.00
                            </Typography>
                            <Typography color="text.secondary" sx={{ flex: 1 }}>
                                on 15 March, 2019
                            </Typography>
                            <div className='mt-4'> <Button variant="outlined" className='rounded' sx={{ bgcolor: '#AFDBF5' }} >View Report</Button></div>
                        </CardContent>

                    </Card> */}
          {/* <div className='bg-white shadow rounded p-3 mx-2 text-center float-right my-5 col-lg-4 col-12'>
                        <h4 style={{ color: 'rgb(46, 150, 255)' }}>Incomes/Expenses</h4>
                        <p>
                            <MovingIcon fontSize='large' sx={{
                                color: '#B9D9EB'
                            }} /></p>
                        <Typography component="p" variant="h4">
                            $3,024.00
                        </Typography>
                        <Typography color="text.secondary" sx={{ flex: 1 }}>
                            on 15 March, 2019
                        </Typography>
                        <div className='mt-4'> <Button variant="outlined" className='rounded' sx={{ bgcolor: '#AFDBF5' }} >View Report</Button></div>
                    </div> */}
        </div>

        <div>
          <div
            className="d-flex flex-column gap-2 float-left"
            style={{ marginTop: 5, width: "100%", maxWidth: 1000 }}
          >
            <div className="my-2 h-25 " style={{ height: 100 }}>
              {isFilterBalanceChart ? (
                <div className="d-flex justify-content-end ">
                  <FilterListIcon
                    onClick={() => {
                      setIsFilterBalanceChart(!isFilterBalanceChart);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ) : (
                <div
                  className="d-flex justify-content-lg-between "
                  style={{ marginLeft: 50 }}
                >
                  {!isFilterBalanceChart ? (
                    <div className="row justify-content-center">
                      <div className="col-lg-4 col-12 px-1">
                        <Select
                          options={options}
                          placeholder="property"
                          styles={{
                            control: (base) => ({
                              ...base,
                              width: 122,
                              border: `1px solid #e5e7eb`,
                              borderRadius: "0.5rem",
                              boxShadow: "none",
                              "&:hover": {
                                border: "1px solid none",
                              },
                            }),
                          }}
                        />
                      </div>

                      <div className="col-lg-4 col-12 mt-lg-0 mt-2 px-1">
                        <label>
                          <input
                            type="date"
                            name="party"
                            className="mx-lg-2 rounded border"
                            style={{
                              color: "#cccccc",
                              borderColor: "#cccccc",
                              borderTopColor: "#cccccc",
                              padding: 6,
                              width: 122,
                            }}
                          />
                        </label>{" "}
                      </div>
                      <div className="col-lg-4 col-12 px-1">
                        <label>
                          <input
                            type="date"
                            name="party"
                            className="mx-lg-2 rounded border"
                            style={{
                              color: "#cccccc",
                              borderColor: "#cccccc",
                              borderTopColor: "#cccccc",
                              padding: 6,
                              width: 122,
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <FilterListOffIcon
                    onClick={() => {
                      setIsFilterBalanceChart(!isFilterBalanceChart);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              )}
            </div>

            <LineChart
              xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
              series={[
                {
                  data: [2, 5.5, 2, 8.5, 1.5, 5],
                  label: "balance",
                },
              ]}
              // width={500}
              height={300}
            />
          </div>
          {/* <Card className='text-center float-right my-5' sx={{ width: 300 }}>
                        <CardHeader title="Balance" component='h4' sx={{
                            color: 'rgb(46, 150, 255)'
                        }}></CardHeader>
                        <CardContent>
                            <p>
                                <MovingIcon fontSize='large' sx={{
                                    color: '#B9D9EB'
                                }} /></p>
                            <Typography component="p" variant="h4">
                                $3,024.00
                            </Typography>
                            <Typography color="text.secondary" sx={{ flex: 1 }}>
                                on 15 March, 2019
                            </Typography>
                            <div className='mt-4'> <Button variant="outlined" className='rounded' sx={{ bgcolor: '#AFDBF5' }} >View Report</Button></div>
                        </CardContent>

                    </Card> */}
        </div>
      </div>

      {/*********DATA TABLE ********/}
      <div
        className="my-4"
        style={{ clear: "right", clear: "left", height: 50 }}
      ></div>
      <h4 style={{ color: "gray" }}> Latest Rented Properties</h4>
      <div className="my-4" style={{ clear: "right", clear: "left" }}>
        {" "}
        <MaterialReactTable table={table} />
      </div>
      {/* <div className='my-4' style={{ clear: 'right', clear: 'left' }}> <LatestOrders orders={orders} title="Latest Rented Properties" /></div> */}
    </>
  );
}

export default InvestorDashboard;
