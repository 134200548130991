export const ROUTES = {
  main: "/dashboard",
  user: "/profile",
  agencyuser: "/agency/profile",
  investoruser: "/investor/profile",
  clientuser: "/client/profile",
  customers: "/",
  inventory: "/",
  property: "/",
  agencyproperties: "/agency/properties",
  agencydashboard: "/agency/dashboard",
  investordashboard: "/investor/dashboard",
  clientdashboard: "/client/dashboard",
};
