import { Padding } from "@mui/icons-material";
import React from "react";
import TuneIcon from "@mui/icons-material/Tune";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import FormatAlignLeftOutlinedIcon from "@mui/icons-material/FormatAlignLeftOutlined";
import LocalCafeOutlinedIcon from "@mui/icons-material/LocalCafeOutlined";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { Link } from "react-router-dom";
function Sidebar() {
  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <nav
      id="sidebar"
      className="sidebar  collapse navbar-collapse sidebar-content "
    >
      <div className="">
        <a className="sidebar-brand" href="/" style={{ padding: 0 }}>
          <span className="align-middle">
            {" "}
            <img
              src={publicUrl + "/assets/img/logo1.png"}
              // avatar
              className="img-fluid rounded-circle"
              alt="logo"
              height={100}
              width={100}
            />
          </span>
        </a>

        <ul className="sidebar-nav">
          <li className="sidebar-header">Pages</li>

          <li className="sidebar-item active">
            <Link className="sidebar-link" to="/dashboard">
              <TuneIcon />
              <span className="align-middle">Dashboard</span>
            </Link>
          </li>

          {/* <li className="sidebar-item">
                        <a className="sidebar-link" href="pages-profile.html">
                            <PermIdentityOutlinedIcon /> <span className="align-middle">Profile</span>
                        </a>
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="pages-sign-in.html">
                            <LoginOutlinedIcon /><span className="align-middle">Sign In</span>
                        </a>
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="pages-sign-up.html">
                            <PersonAddAltOutlinedIcon /> <span className="align-middle">Sign Up</span>
                        </a>
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="pages-blank.html">
                            <BookOutlinedIcon /> <span className="align-middle">Blank</span>
                        </a>
                    </li>
               

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="ui-buttons.html">
                            <CheckBoxOutlineBlankOutlinedIcon /><span className="align-middle">Buttons</span>
                        </a>
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="ui-forms.html">
                            <CheckBoxOutlinedIcon /> <span className="align-middle">Forms</span>
                        </a>
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="ui-cards.html">
                            <SpaceDashboardOutlinedIcon /><span className="align-middle">Cards</span>
                        </a>
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="ui-typography.html">
                            <FormatAlignLeftOutlinedIcon /><span className="align-middle">Typography</span>
                        </a>
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="icons-feather.html">
                            <LocalCafeOutlinedIcon /><span className="align-middle">Icons</span>
                        </a>
                    </li>
             

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="charts-chartjs.html">
                            <LeaderboardOutlinedIcon /> <span className="align-middle">Charts</span>
                        </a>
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="maps-google.html">
                            <MapOutlinedIcon /><span className="align-middle">Maps</span>
                        </a>
                    </li> */}
        </ul>

        {/* <div className="sidebar-cta">
                    <div className="sidebar-cta-content">
                        <strong className="d-inline-block mb-2">Upgrade to Pro</strong>
                        <div className="mb-3 text-sm">
                            Are you looking for more components? Check out our premium version.
                        </div>
                        <div className="d-grid">
                            <a href="upgrade-to-pro.html" className="btn btn-primary">Upgrade to Pro</a>
                        </div>
                    </div>
                </div> */}
      </div>
    </nav>
  );
}

export default Sidebar;
