import {
  Dashboard as DashboardIcon,
  ShoppingCart as ShoppingCartIcon,
  People as PeopleIcon,
  AttachMoney as AttachMoneyIcon,
} from "@mui/icons-material";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import PersonIcon from "@mui/icons-material/Person";
// import { IMenuItem } from '../types';
import { ROUTES } from "./routes";

export const MENU_LIST = [
  {
    route: ROUTES.investordashboard,
    literal: "Dashboard",
    Icon: DashboardIcon,
  },
  {
    route: ROUTES.property,
    literal: "Properties",
    Icon: HomeWorkIcon,
  },
  {
    route: ROUTES.investoruser,
    literal: "Profile",
    Icon: PersonIcon,
  },
  {
    route: ROUTES.customers,
    literal: "Customers",
    Icon: PeopleIcon,
  },
  // {
  //   route: ROUTES.inventory,
  //   literal: "Inventory",
  //   Icon: AttachMoneyIcon,
  // },
];
