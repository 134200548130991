import React, { Component } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Routes,
} from "react-router-dom";

import blogdata from "./data/blogdata.json";
import Singleblogdata from "./data/single-blogdata.json";
import HomeV1 from "./components/home-v1";
import HomeV2 from "./components/home-v2";
import HomeV3 from "./components/home-v3";
import HomeV4 from "./components/home-v4";
import Property from "./components/property";
import AvilableProperty from "./components/availavbe-property";
import PropertiesByCity from "./components/properties-by-city";
import RecentProperties from "./components/recent-properties";
import PropertyDetails from "./components/property-details";
import About from "./components/about";
import Advisor from "./components/advisor";
import Pricing from "./components/pricing";
import UserList from "./components/user-list";
import Registraion from "./components/registration";
import Error from "./components/error";
import Faq from "./components/faq";
import News from "./components/news";
import NewsDetails from "./components/news-details";
import Contact from "./components/contact";
import SearchMap from "./components/search-map";
import SearchGrid from "./components/search-grid";
import SearchList from "./components/search-list";
import AddNew from "./components/add-property";
import SignInSide from "./components/authentication/signin";
import SignUp from "./components/authentication/signup";
import PasswordVerify from "./components/authentication/password-verify";
import ForgetPassword from "./components/authentication/forget-password";
import ResetPassword from "./components/authentication/reset-password";
import EmailVerify from "./components/authentication/email-verify";
import PricingSection from "./components/section-components/pricing";
import { ProtectedRoute } from "./components/ProtectedRoutes/ProtectedRoute";
import Sidebar from "./components/adminKit/sidebar";

import Profile from "./components/adminKit/components/user/profile";
import PropertyDetailsDashboard from "./components/adminKit/property-details";
import Dashboard from "./components/adminKit/components/dashboard/dashboard";
import AgencyDashboard from "./components/adminKit/components/agency/dashboard";
import InvestorDashboard from "./components/adminKit/components/investor/dashboard";
import ClientDashboard from "./components/adminKit/components/client/dashboard";
import AgencyProperties from "./components/adminKit/components/agency/properties/properties";
import AddProperty from "./components/adminKit/components/agency/properties/add-property";
import EditProperty from "./components/adminKit/components/agency/properties/edit-property";
class Root extends Component {
  render() {
    return (
      <Router basename="/">
        <Routes>
          <Route index path="/" element={<HomeV1 />} />
          {/*  exact*/}
          <Route exact path="/" element={<HomeV1 />} />
          <Route path="/home-v2" element={<HomeV2 />} />
          <Route path="/home-v3" element={<HomeV3 />} />
          <Route path="/home-v4" element={<HomeV4 />} />
          <Route path="/property" element={<Property />} />
          <Route path="/availavbe-property" element={<AvilableProperty />} />
          <Route path="/properties-by-city" element={<PropertiesByCity />} />
          <Route path="/recent-properties" element={<RecentProperties />} />
          <Route path="/property-details" element={<PropertyDetails />} />
          <Route path="/about" element={<About />} />
          <Route path="/advisor" element={<Advisor />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/user-list" element={<UserList />} />
          <Route path="/registration" element={<Registraion />} />
          <Route path="/error" element={<Error />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/news" element={<News />} />
          <Route path="/news-details" element={<NewsDetails />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/search-map" element={<SearchMap />} />
          <Route path="/search-grid" element={<SearchGrid />} />
          <Route path="/search-list" element={<SearchList />} />
          <Route path="/add-property" element={<AddNew />} />
          <Route path="/signin" element={<SignInSide />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/password-verify" element={<PasswordVerify />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/email-verify" element={<EmailVerify />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route
            path="/agency/dashboard"
            element={
              <ProtectedRoute>
                <AgencyDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/agency/properties"
            element={
              <ProtectedRoute>
                <AgencyProperties />
              </ProtectedRoute>
            }
          />
          <Route
            path="/agency/add-property"
            element={
              <ProtectedRoute>
                <AddProperty />
              </ProtectedRoute>
            }
          />
          <Route
            path="/agency/edit-property/:id"
            element={
              <ProtectedRoute>
                <EditProperty />
              </ProtectedRoute>
            }
          />

          <Route
            path="/investor/dashboard"
            element={
              <ProtectedRoute>
                <InvestorDashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/client/dashboard"
            element={
              <ProtectedRoute>
                <ClientDashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/pricing-section"
            element={
              // <ProtectedRoute isAllowed={localStorage.getItem('access_token')} redirectTo='/signin'>
              <PricingSection />
              // </ProtectedRoute>
            }
          />

          <Route
            path="/client/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/agency/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/investor/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />

          <Route
            path="/property-dashboard-details"
            element={<PropertyDetailsDashboard />}
          />
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    );
  }
}
//  <HashRouter basename="/">
export default Root;
const root = ReactDOM.createRoot(document.getElementById("realdeal"));
root.render(<Root />);
