import React from "react";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import DefaultImage from "./assets/user.jpg";
import EditIcon from "./assets/edit.jpg";
import AvatarUpload from "./AvatarUpload";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Snackbar from "@mui/joy/Snackbar";
import { confirmAlert } from "react-confirm-alert";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import * as Yup from "yup";

import moment from "moment";
// mui@material
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import Link from '@mui/material/Link';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LinearProgress } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";

import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

function EditProfile() {
  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");
  const decoded = jwtDecode(token);
  const [role, setRole] = useState(decoded.role);
  const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(true);
  const [avatarURL, setAvatarURL] = useState(DefaultImage);
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [financeValue, setFinanceValue] = useState("");
  const [file, setFile] = useState(DefaultImage);

  const [base64File, setBase64File] = useState("");

  const [errorFinanceRequired, setErrorFinanceRequired] = useState(false);
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [userNameAvailabilityCheck, setUserNameAvailabilityCheck] =
    useState("");
  const [loadingUserNameAvailability, setLoadingUserNameAvailability] =
    useState(false);
  const [openConfirmDeletePopup, setOpenConfirmDeletePopup] = useState(false);
  const fileUploadRef = useRef();

  const handleImageUpload = (event) => {
    event.preventDefault();
    fileUploadRef.current.click();
  };

  useEffect(() => {
    document.title = "profile";
    const today = new Date();
    const yyyy = today.getFullYear();
    var mm = today.getMonth() + 1;
    if (mm < 10) {
      var mm = "0" + mm;
    }

    var dd = today.getDate();
    if (dd < 10) {
      var dd = "0" + today.getDate();
    }

    const formattedToday = yyyy + "-" + mm + "-" + dd;

    console.log(formattedToday);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/user`, {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
      .then((response) => {
        console.log(response);
        setIsLoadingUserInfo(false);
        setUserName(response.data.data.user.username);
        setFirstName(response.data.data.user.firstname);
        setLastName(response.data.data.user.lastname);
        setEmail(response.data.data.user.email);
        setAddress(response.data.data.user.address);

        setAvatarURL(
          response.data.data.user.profile_picture
            ? response.data.data.user.profile_picture
            : response.data.data.user.profile_photo_url
        );
        setFinanceValue(
          response.data.data.user.finance ? response.data.data.user.finance : ""
        );
        setDescription(response.data.data.user.description);

        // console.log(
        //   response?.data.data?.user?.last_username_change.split(" ")[0]
        // );
        // setLoading(false)

        console.log(response);
        // setPricingData(response.data.properties)
      })
      .catch((error) => {
        setIsLoadingUserInfo(false);
        console.log(error);
      });
  }, []);

  const deleteAccount = () => {
    setIsLoadingUserInfo(true);
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/user/delete`,

        {
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      .then((res) => {
        setIsLoadingUserInfo(false);
        setOpenConfirmDeletePopup(false);
        localStorage.removeItem("access_token");
        localStorage.removeItem("email");
        localStorage.removeItem("name");

        localStorage.removeItem("loggedIn");

        navigate("/signin");
      })
      .catch((error) => {
        setIsLoadingUserInfo(false);
        console.log(error);
      });
  };
  function handleChange(e) {
    console.log(e.target.files);
    if (e.target.files[0]) {
      setAvatarURL(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
      handleFileRead(e.target.files[0]);
    }

    console.log(base64File);
    // console.log(file);
  }
  const onBlur = (e) => {
    setLoadingUserNameAvailability(true);
    localStorage.setItem("name", e.target.value);
    console.log(localStorage.getItem("name"));
    // console.log(e.target.value)
    // console.log(values.username)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/user/check-username`,
        {
          new_username: e?.target.value,
        },
        {
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      .then((res) => {
        setLoadingUserNameAvailability(false);
        setUserNameAvailabilityCheck(res.data.success);
      })
      .catch((error) => {
        console.log(error);
        setLoadingUserNameAvailability(false);
        setUserNameAvailabilityCheck(error.response.data.error);
      });
  };
  const deleteImage = (event) => {
    event.preventDefault();
    setAvatarURL(DefaultImage);
    setFile(DefaultImage);
    console.log(file);
  };

  const resetHandleText = (e) => {
    setErrorFinanceRequired(false);
    setFinanceValue(e.target.value);
  };

  const initialValues = {
    username: userName,
    firstname: firstName ? firstName : "",
    lastname: lastName ? lastName : "",
    email: email ? email : "",
    address: address ? address : "",
    description: description,
    financing: financeValue,
  };

  const SigninSchema = Yup.object().shape({
    username: Yup.string().required("username is required*"),
    firstname: Yup.string().required("first name is required*"),
    lastname: Yup.string().required("last name is required*"),
    address: Yup.string().required("address is required*"),
    description: Yup.string().required("description is required*"),
    financing:
      role == "agency"
        ? Yup.string().required("finance is required*")
        : Yup.string(),
  });

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileRead = async (file) => {
    const base64 = await convertBase64(file);
    setBase64File(base64);
    console.log(base64);
  };

  const onsubmit = (values, props) => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object

    formData.append("profile_picture", file);

    console.log(formData.get("profile_picture"));

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/user/edit`,
        {
          email: email,
          firstname: values.firstname,
          lastname: values.lastname,
          // phone_number: "123-456-7890",
          address: values.address,
          finance: values.financing,
          username: values.username,
          // avatarURL.split("blob:")[1],
          profile_picture: base64File,
          // password: "newPassword12345"
          description: values.description,
        },
        {
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      .then((res) => {
        console.log(res);
        props.setSubmitting(false);
        localStorage.setItem("name", values.username);
        localStorage.setItem("Picture", base64File);
        console.log(localStorage.getItem("name"));

        changeGlobal(localStorage.getItem("name"));
        changeGlobalPicture(localStorage.getItem("Picture"));
        setOpen(true);
      })
      .catch((error) => {
        props.setSubmitting(false);
        console.log(error);
        setOpenError(true);
      });
  };

  function changeGlobal(value) {
    var event = new CustomEvent("myEvent", { detail: value });
    document.dispatchEvent(event);
  }
  function changeGlobalPicture(value) {
    var event = new CustomEvent("myPictureEvent", { detail: value });
    document.dispatchEvent(event);
  }

  console.log(userNameAvailabilityCheck);
  return (
    <div>
      <div className="d-flex justify-content-between">
        <h4 style={{ color: "gray" }}>Profile Information</h4>
        <Button
          onClick={() => {
            setOpenConfirmDeletePopup(true);
          }}
          variant="contained"
          sx={{
            bgcolor: "red",
            mx: 4,
            mt: 0,
            mb: 2,
            ":hover": {
              bgcolor: "red", // "primary.main" theme.palette.primary.main
              color: "white",
            },
          }}
        >
          Delete Account
        </Button>
      </div>

      <div className="d-flex justify-content-center">
        {isLoadingUserInfo ? (
          <CircularProgress />
        ) : (
          <Grid
            item
            xs={12}
            sm={6}
            md={5}
            sx={{
              backgroundColor: "transparent",
            }}
          >
            <div className="d-flex justify-content-center">
              <div
                className="position-relative  m-8"
                style={{ width: 100, height: 100 }}
              >
                <div className="d-flex">
                  <img
                    src={avatarURL}
                    alt="Avatar"
                    className="rounded-circle"
                    height={100}
                    width={100}
                  />
                  {avatarURL != DefaultImage ? (
                    <Link
                      onClick={deleteImage}
                      type="submit"
                      className="d-flex justify-content-end align-items-end text-decoration-underline"
                    >
                      remove
                    </Link>
                  ) : (
                    ""
                  )}
                </div>

                <form id="form" encType="multipart/form-data">
                  <button
                    type="submit"
                    onClick={handleImageUpload}
                    className="flex-center position-absolute bottom-12  rounded-circle"
                    style={{ bottom: 0, right: 0, border: "none " }}
                  >
                    <img
                      src={EditIcon}
                      alt="Edit"
                      className="object-cover"
                      height={20}
                      width={20}
                    />
                  </button>
                  <input
                    type="file"
                    id="file"
                    accept="image/*"
                    ref={fileUploadRef}
                    onChange={handleChange}
                    hidden
                  />
                </form>
              </div>
            </div>

            <div className="">
              <Formik
                initialValues={initialValues}
                onSubmit={onsubmit}
                validationSchema={SigninSchema}
                validateOnBlur={false}
              >
                {(props) => (
                  <Form>
                    {console.log(props)}
                    <div className="row my-5">
                      {role != "client" ? (
                        <div className="col-lg-6 col-12">
                          <div>
                            <Field
                              as={TextField}
                              margin="normal"
                              variant="standard"
                              fullWidth
                              id="username"
                              label="Username"
                              name="username"
                              autoComplete="username"
                              onBlur={onBlur}
                              helperText={<ErrorMessage name="username" />}
                            />
                            {loadingUserNameAvailability ? (
                              <div className="d-flex justify-content-center align-items-center mt-3">
                                {" "}
                                <CircularProgress size={20} />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {userNameAvailabilityCheck ? (
                            <p
                              style={{
                                color:
                                  userNameAvailabilityCheck ===
                                  "Username available"
                                    ? "green"
                                    : "#871616",

                                fontSize: "12px",
                                fontStyle: "italic",
                                marginTop: "-7px",
                              }}
                            >
                              {userNameAvailabilityCheck.toLowerCase()}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="col-lg-6 col-12">
                        <Field
                          as={TextField}
                          margin="normal"
                          variant="standard"
                          fullWidth
                          id="firstname"
                          label="First Name"
                          name="firstname"
                          autoComplete="firstname"
                          helperText={<ErrorMessage name="firstname" />}
                        />
                      </div>
                      <div className="col-lg-6 col-12">
                        <Field
                          as={TextField}
                          margin="normal"
                          variant="standard"
                          fullWidth
                          id="lastname"
                          label="Last Name"
                          name="lastname"
                          autoComplete="lastname"
                          helperText={<ErrorMessage name="lastname" />}
                        />
                      </div>

                      <div className="col-lg-6 col-12">
                        <Field
                          as={TextField}
                          margin="normal"
                          variant="standard"
                          fullWidth
                          id="email"
                          label="Email"
                          name="email"
                          autoComplete="email"
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 col-12">
                        <Field
                          as={TextField}
                          margin="normal"
                          variant="standard"
                          fullWidth
                          multiline
                          rows={4}
                          id="address"
                          label="Address"
                          name="address"
                          autoComplete="address"
                          helperText={<ErrorMessage name="address" />}
                        />
                      </div>
                      <div className="col-lg-6 col-12">
                        <Field
                          as={TextField}
                          multiline
                          rows={4}
                          margin="normal"
                          variant="standard"
                          fullWidth
                          id="description"
                          label="Description"
                          name="description"
                          autoComplete="description"
                          helperText={<ErrorMessage name="description" />}
                        />
                      </div>
                      {role == "agency" ? (
                        <div className="col-12">
                          <div id="my-radio-group">Financing</div>
                          <div role="group">
                            <label
                              className="mr-3"
                              style={{ cursor: "pointer" }}
                            >
                              {/* onChange={resetHandleText} checked={financeValue == 'CASH'}  */}
                              <Field
                                type="radio"
                                name="financing"
                                value="cash"
                                onBlur={resetHandleText}
                              />
                              <span className="mx-2">CASH</span>
                            </label>
                            <label
                              className="mx-lg-3 mx-0"
                              style={{ cursor: "pointer" }}
                            >
                              <Field
                                type="radio"
                                name="financing"
                                value="mortgage"
                                onBlur={resetHandleText}
                              />
                              <span className="mx-2">MORTGAGE</span>
                            </label>

                            <ErrorMessage name="financing">
                              {(msg) => (
                                <div
                                  className="font-italic"
                                  style={{ color: "#871616", fontSize: "12px" }}
                                >
                                  {msg}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* /  bgcolor: '#222e3c', */}
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button
                        className="col-lg-3 col-12"
                        type="submit"
                        fullWidth
                        disabled={props.isSubmitting}
                        variant="contained"
                        sx={{
                          mt: 3,
                          mb: 2,
                          ":hover": {
                            bgcolor: "#0f2c4e", // "primary.main" theme.palette.primary.main
                            color: "white",
                          },
                        }}
                      >
                        Save
                      </Button>
                    </div>

                    {props.isSubmitting ? (
                      <LinearProgress
                        sx={{
                          mt: 1,
                          mb: 2,
                        }}
                      />
                    ) : (
                      ""
                    )}

                    <Snackbar
                      autoHideDuration={6000}
                      variant="soft"
                      color="success"
                      open={open}
                      onClose={() => setOpen(false)}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
                      endDecorator={
                        <Button
                          onClick={() => setOpen(false)}
                          size="sm"
                          variant="soft"
                          color="success"
                        >
                          DIMISS
                        </Button>
                      }
                    >
                      Your Profile was been Updated successfully.
                    </Snackbar>

                    <Snackbar
                      autoHideDuration={6000}
                      variant="soft"
                      color="danger"
                      open={openError}
                      onClose={() => setOpenError(false)}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
                      endDecorator={
                        <Button
                          onClick={() => setOpenError(false)}
                          size="sm"
                          variant="soft"
                          color="danger"
                        >
                          DIMISS
                        </Button>
                      }
                    >
                      Unsuccessfull Update Profile.
                    </Snackbar>
                  </Form>
                )}
              </Formik>
            </div>
          </Grid>
        )}

        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openConfirmDeletePopup}
          onClose={() => setOpenConfirmDeletePopup(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 500,
              borderRadius: "md",
              p: 4,
              boxShadow: "lg",
            }}
          >
            <ModalClose variant="plain" sx={{ m: 1 }} />

            <Typography
              sx={{
                margin: 3,
                color: "gray",
              }}
            >
              Are You Sure You Want To Delete This Account?
            </Typography>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-light mx-2"
                onClick={() => setOpenConfirmDeletePopup(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger mx-2"
                onClick={() => deleteAccount()}
              >
                Delete
              </button>
            </div>
          </Sheet>
        </Modal>
      </div>
    </div>
  );
}

export default EditProfile;
