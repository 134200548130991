import React from "react";

import EditProfile from "./edit-profile";
import Sidebar from "../../sidebar";
import Navbar from "../../navbar";
import { useState } from "react";
import { CssBaseline } from "@mui/material";
import { DrawerContextProvider } from "../../contexts/drawer-context";
import { Drawer } from "../layout/Drawer";
import Header from "../layout/Header";
import Footer from "../../footer";
function Profile() {
  return (
    // <div className="wrapper">
    //   <Sidebar />
    //   <div className="main">
    //     <Navbar />

    //     <main className="content">
    //       <div className="container-fluid p-0">
    //         <EditProfile />
    //       </div>
    //     </main>
    //     {/* <Footer /> */}
    //   </div>
    // </div>
    <>
      <CssBaseline />
      <DrawerContextProvider>
        <div className="wrapper">
          <Drawer />
          <div className="main">
            <Header />
            <main className="content">
              <div className="container-fluid p-0">
                <EditProfile />
              </div>
            </main>
            <Footer></Footer>
          </div>
        </div>
      </DrawerContextProvider>
    </>
  );
}

export default Profile;
