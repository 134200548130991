import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./authentication.css";

//  mui@material
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import Link from '@mui/material/Link';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LinearProgress } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme({
  typography: {
    fontFamily: ["ITC Avant Garde Gothic W05 X Lt"].join(","),
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              backgroundColor: "#015a80",
              color: "#fff",
            }),
        }),
      },
    },
  },
});

export default function PasswordVerify() {
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCodeMessage, setCodeErrorMessage] = useState("");
  const [errorResendMessage, setErrorResendMessage] = useState("");
  const [resendCode, setResendCode] = useState(false);

  const [isDesktop, setDesktop] = useState(window.innerWidth > 598);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 598);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  useEffect(() => {
    document.title = "Password Verify";
  }, []);

  const location = useLocation();
  const email = location.state;
  const navigate = useNavigate();
  let publicUrl = process.env.PUBLIC_URL + "/";
  // const handleSubmit = (event) => {
  //     event.preventDefault();
  //     const data = new FormData(event.currentTarget);
  //     console.log({
  //         email: data.get('email'),

  //     });
  //     navigate('/reset-password')
  // };

  const initialValues = {
    code: "",
  };
  const ValidationSchema = Yup.object().shape({
    code: Yup.string().required("please fill the verification code*"),
  });

  const onsubmit = (values, props) => {
    console.log(props);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/forgetpass/verify`,
        {
          token: values.code,
        },
        {
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
        props.setSubmitting(false);
        console.log(email);
        navigate("/reset-password", { state: email });
      })
      .catch((error) => {
        props.setSubmitting(false);
        console.log(error);
        if (error.response.status == 400) {
          setCodeErrorMessage("invalide code");
        } else if (error.response.status == 422) {
          setCodeErrorMessage("code is expired");
        } else {
          setErrorMessage("something went wrong, please try again");
        }
      });
  };

  const resend = (e) => {
    e.preventDefault();
    setResendCode(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/forgetpass`,
        {
          email: email,
        },
        {
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setResendCode(false);
      })
      .catch((error) => {
        setResendCode(false);
        setErrorResendMessage("something went wrong, please try again");
      });
  };
  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={6}
            sx={{
              backgroundColor: "#0f2c4e",
              backgroundImage: `url(${publicUrl + "/assets/img/logo1.png"})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          />

          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 2,
              }}
            >
              {isDesktop ? (
                <Avatar sx={{ m: 1, bgcolor: "#015a80" }}>
                  <EmailIcon />
                </Avatar>
              ) : (
                <img
                  src={publicUrl + "/assets/img/logo1.png"}
                  width={100}
                  height={100}
                />
              )}
              <Typography component="h1" variant="h5">
                Verify Your Email
              </Typography>
              <Box sx={{ mt: 1 }}>
                {errorMessage || errorResendMessage ? (
                  <Alert variant="outlined" severity="error">
                    {errorMessage || errorResendMessage}
                  </Alert>
                ) : (
                  ""
                )}
                <Typography
                  component="span"
                  variant="h8"
                  sx={{
                    color: "gray",
                    fontSize: 15,
                  }}
                >
                  Enter the verification code sent to your email address:{" "}
                  {email}
                </Typography>

                <Formik
                  initialValues={initialValues}
                  validateOnBlur={false}
                  onSubmit={onsubmit}
                  validationSchema={ValidationSchema}
                >
                  {(props) => (
                    <Form>
                      <Field
                        as={TextField}
                        margin="normal"
                        required
                        fullWidth
                        inputProps={{ maxLength: 4 }}
                        id="code"
                        label="Verification Code"
                        name="code"
                        autoComplete="off"
                        autoFocus
                        helperText={<ErrorMessage name="code" />}
                      />
                      {errorCodeMessage ? (
                        <p
                          style={{
                            color: "#871616",
                            fontSize: "12px",
                            fontStyle: "italic",
                            marginTop: "-7px",
                          }}
                        >
                          {errorCodeMessage.toLowerCase()}
                        </p>
                      ) : (
                        ""
                      )}

                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={props.isSubmitting}
                        sx={{
                          mt: 3,
                          mb: 2,
                          ":hover": {
                            bgcolor: "#0f2c4e", // "primary.main" theme.palette.primary.main
                            color: "white",
                          },
                        }}
                      >
                        Verify
                      </Button>
                      {props.isSubmitting ? (
                        <LinearProgress
                          sx={{
                            mt: 1,
                            mb: 2,
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Form>
                  )}
                </Formik>

                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  {/* xs */}
                  <Grid item>
                    <Typography
                      component="div"
                      variant="h8"
                      sx={{
                        color: "gray",
                        mt: 2,
                        mb: 3,
                        textAlign: "center",
                        fontSize: 15,
                      }}
                    >
                      You have 4 mins for the received OTP, or you will need to
                      resend again.
                    </Typography>
                  </Grid>

                  <Grid item xs></Grid>

                  <Grid item xs={3}>
                    <Typography
                      component="span"
                      variant="h8"
                      sx={{
                        px: 1,
                        color: "gray",
                        textAlign: "center",
                      }}
                    >
                      Didn't receive a code?
                    </Typography>
                    {resendCode ? (
                      <span style={{ fontStyle: "italic", fontSize: "15px" }}>
                        Loading...
                      </span>
                    ) : (
                      <Link
                        onClick={resend}
                        component="button"
                        style={{
                          textDecoration: "underline",
                          color: "#0077c0",
                        }}
                      >
                        Resend
                      </Link>
                    )}
                    {/* <Link href="/signup" variant="body2" sx={{
                                            ':hover': {
                                                color: '#0f2c4e',
                                            },
                                        }}>
                                            {"Resend"}
                                        </Link> */}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}
