import * as React from "react";
import { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./authentication.css";

// mui@material
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import Link from '@mui/material/Link';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LinearProgress } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme({
  typography: {
    fontFamily: ["ITC Avant Garde Gothic W05 X Lt"].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              backgroundColor: "#015a80",
              color: "#fff",
            }),
        }),
      },
    },
  },
});

export default function SignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorEmailMessage, setEmailErrorMessage] = useState("");
  const [isDesktop, setDesktop] = useState(window.innerWidth > 598);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 598);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  useEffect(() => {
    document.title = "Sign Up";
  }, []);
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate("/");
  };
  let publicUrl = process.env.PUBLIC_URL + "/";

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   console.log({
  //     email: data.get('email'),
  //     password: data.get('password'),
  //     confirm_password: data.get('confirm_password')
  //   });
  // };

  const initialValues = {
    email: "",
    password: "",
    confirm_password: "",
  };
  const SignUpSchema = Yup.object().shape({
    email: Yup.string()
      .email("please enter valide email")
      .required("email is required*"),
    password: Yup.string()
      .required("password is required*")
      .min(8, "password is too short - should be 8 characters minimum.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
    confirm_password: Yup.string()
      .required("confirm password is required*")
      .oneOf([Yup.ref("password"), null], "passwords must match"),
  });

  const onsubmit = (values, props) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/signup`,
        {
          registration_type: "manual",
          email: values.email,
          password: values.password,
          confirm_password: values.confirm_password,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: false,
        }
      )
      .then((res) => {
        const sentData = values;
        props.setSubmitting(false);
        const data = { email: values.email };
        console.log(data);
        localStorage.setItem("access_token", res.data.data.access.token);
        // localStorage.setItem("email", data.email);
        // localStorage.setItem("name", data.name);
        console.log(localStorage.getItem("access_token"));
        navigate("/email-verify", { state: data });

        //navigate to email-verify with state token
      })
      .catch((error) => {
        console.log(error);
        props.setSubmitting(false);
        if (error?.response) {
          // email already exist
          if (error?.response?.status == 409) {
            setEmailErrorMessage(error.response.data.message);
          } else {
            setErrorMessage(error.response.data.error);
          }
        }
        if (error?.code == "ERR_NETWORK") {
          setErrorMessage(error.message);
        }
      });
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundColor: "#0f2c4e",
            backgroundImage: `url(${publicUrl + "/assets/img/logo1.png"})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        />

        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 2,
            }}
          >
            {isDesktop ? (
              <Avatar sx={{ m: 1, bgcolor: "#015a80" }}>
                <LockOutlinedIcon />
              </Avatar>
            ) : (
              <img
                src={publicUrl + "/assets/img/logo1.png"}
                width={100}
                height={100}
              />
            )}

            <Typography component="h1" variant="h5">
              Sign Up
            </Typography>
            {/* component="form" noValidate onSubmit={handleSubmit}  */}
            <Box sx={{ mt: 1 }}>
              {errorMessage ? (
                <Alert variant="outlined" severity="error">
                  {errorMessage}
                </Alert>
              ) : (
                ""
              )}

              <Formik
                initialValues={initialValues}
                validateOnBlur={false}
                onSubmit={onsubmit}
                validationSchema={SignUpSchema}
              >
                {(props) => (
                  <Form>
                    <Field
                      as={TextField}
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      helperText={<ErrorMessage name="email" />}
                    />
                    {errorEmailMessage ? (
                      <p
                        style={{
                          color: "#871616",
                          fontSize: "12px",
                          fontStyle: "italic",
                          marginTop: "-7px",
                        }}
                      >
                        {errorEmailMessage.toLowerCase()}
                      </p>
                    ) : (
                      ""
                    )}
                    <Field
                      as={TextField}
                      margin="normal"
                      fullWidth
                      name="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      id="password"
                      autoComplete="current-password"
                      helperText={<ErrorMessage name="password" />}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Field
                      as={TextField}
                      margin="normal"
                      fullWidth
                      name="confirm_password"
                      label="Confirm Password"
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirm_password"
                      autoComplete="confirm_password"
                      helperText={<ErrorMessage name="confirm_password" />}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={props.isSubmitting}
                      sx={{
                        mt: 3,
                        mb: 2,
                        ":hover": {
                          bgcolor: "#0f2c4e", // "primary.main" theme.palette.primary.main
                          color: "white",
                        },
                      }}
                    >
                      Sign Up
                    </Button>
                    {props.isSubmitting ? (
                      <LinearProgress
                        sx={{
                          mt: 1,
                          mb: 2,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Form>
                )}
              </Formik>

              <Grid container sx={{ justifyContent: "center" }}>
                <Grid item xs></Grid>
                <Grid item>
                  <span style={{ color: "gray" }} className="mx-2">
                    Already have an account?
                  </span>
                  <Link
                    to="/signin"
                    style={{ textDecoration: "underline", color: "#0077c0" }}
                  >
                    Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
