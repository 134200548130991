import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./authentication.css";

// @mui/material
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import Link from '@mui/material/Link';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LinearProgress } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { Link } from "react-router-dom";

import axios from "axios";
import { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme({
  typography: {
    fontFamily: ["ITC Avant Garde Gothic W05 X Lt"].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              backgroundColor: "#015a80",
              color: "#fff",
            }),
        }),
      },
    },
  },
});

export default function ForgetPassword() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorEmailMessage, setEmailErrorMessage] = useState("");
  const [isDesktop, setDesktop] = useState(window.innerWidth > 598);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 598);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  useEffect(() => {
    document.title = "Forget Password";
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";
  // const handleSubmit = (event) => {
  //     event.preventDefault();
  //     const data = new FormData(event.currentTarget);
  //     console.log({
  //         email: data.get('email'),
  //         password: data.get('password'),
  //     });
  //     const email = data.get('email')
  //     navigate("/password-verify", { state: email });
  // };
  const initialValues = {
    email: "",
  };

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("please enter valid email")
      .required("email is required*"),
  });

  const onsubmit = (values, props) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/forgetpass`,
        {
          email: values.email,
        },
        {
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        props.setSubmitting(false);
        navigate("/password-verify", { state: values.email });
      })
      .catch((error) => {
        props.setSubmitting(false);
        if (error.response.status === 404) {
          setEmailErrorMessage(error.response.data.message);
        } else {
          setErrorMessage("something went wrong");
        }
      });
  };

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={6}
            sx={{
              backgroundColor: "#0f2c4e",
              backgroundImage: `url(${publicUrl + "/assets/img/logo1.png"})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          />

          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 2,
              }}
            >
              {isDesktop ? (
                <Avatar sx={{ m: 1, bgcolor: "#015a80" }}>
                  <LockIcon />
                </Avatar>
              ) : (
                <img
                  src={publicUrl + "/assets/img/logo1.png"}
                  width={100}
                  height={100}
                />
              )}

              <Typography component="h1" variant="h5">
                Forget Password
              </Typography>

              <Box sx={{ mt: 1 }}>
                {errorMessage ? (
                  <Alert variant="outlined" severity="error">
                    {errorMessage}
                  </Alert>
                ) : (
                  ""
                )}
                <Typography
                  component="span"
                  variant="h8"
                  sx={{
                    color: "gray",
                    fontSize: 13,
                  }}
                >
                  Enter your email and we'll send you instructions to reset your
                  password:
                </Typography>
                <Formik
                  initialValues={initialValues}
                  validateOnBlur={false}
                  onSubmit={onsubmit}
                  validationSchema={ValidationSchema}
                >
                  {(props) => (
                    <Form>
                      <Field
                        as={TextField}
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        helperText={<ErrorMessage name="email" />}
                      />

                      {errorEmailMessage ? (
                        <p
                          style={{
                            color: "#871616",
                            fontSize: "12px",
                            fontStyle: "italic",
                            marginTop: "-7px",
                          }}
                        >
                          {errorEmailMessage.toLowerCase()}
                        </p>
                      ) : (
                        ""
                      )}
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={props.isSubmitting}
                        sx={{
                          mt: 3,
                          mb: 2,
                          ":hover": {
                            bgcolor: "#0f2c4e", // "primary.main" theme.palette.primary.main
                            color: "white",
                          },
                        }}
                      >
                        Send Otp
                      </Button>
                      {props.isSubmitting ? (
                        <LinearProgress
                          sx={{
                            mt: 1,
                            mb: 2,
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Form>
                  )}
                </Formik>

                <Grid container>
                  <Grid item xs></Grid>
                  <Grid item>
                    <Link
                      to="/signin"
                      style={{ textDecoration: "underline", color: "#0077c0" }}
                    >
                      {"< Back to Sign In"}
                    </Link>

                    {/* <Link href="/signin" variant="body2" sx={{
                                            ':hover': {
                                                color: '#0f2c4e',
                                            },
                                        }}>
                                            {"<  Back to Sign In"}
                                        </Link> */}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}
