import { useLocation } from "react-router-dom";
import { List, Grid } from "@mui/material";

import { MenuItem } from "./MenuItem";

export const MenuItemsList = ({ items = [] }) => {
  const { pathname } = useLocation();

  if (!items.length) return null;
  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <Grid>
      <a className="sidebar-brand" href="/" style={{ padding: 0 }}>
        <span className="align-middle">
          {" "}
          <img
            src={publicUrl + "/assets/img/logo1.png"}
            // avatar
            className="img-fluid rounded-circle"
            alt="logo"
            height={100}
            width={100}
          />
        </span>
      </a>
      <List sx={{ p: 0 }}>
        {items.map(({ literal, route, Icon }) => (
          <MenuItem
            style={{
              margin: "10px 0 20px 0",
              color: "gray",
            }}
            Icon={Icon}
            literal={literal}
            route={route}
            key={route}
            selected={pathname === route}
          />
        ))}
      </List>
    </Grid>
  );
};
