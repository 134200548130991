import * as React from "react";
import { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./authentication.css";

// mui@material
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import Link from '@mui/material/Link';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LinearProgress } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Alert from "@mui/material/Alert";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  GoogleLoginButton,
  InstagramLoginButton,
} from "react-social-login-buttons";
import { LoginSocialGoogle, LoginSocialInstagram } from "reactjs-social-login";
import CloseIcon from "@mui/icons-material/Close";
import { SocialIcon } from "react-social-icons";
import { useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme({
  typography: {
    fontFamily: ["ITC Avant Garde Gothic W05 X Lt"].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              backgroundColor: "#015a80",
              color: "#fff",
            }),
        }),
      },
    },
  },
});

export default function SignInSide() {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDesktop, setDesktop] = useState(window.innerWidth > 598);
  const [msg, setMsg] = useState("");

  const updateMedia = () => {
    setDesktop(window.innerWidth > 598);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  useEffect(() => {
    document.title = "Sign In";
  }, []);
  const navigate = useNavigate();
  let publicUrl = process.env.PUBLIC_URL + "/";

  const initialValues = {
    email: "",
    password: "",
  };
  const SigninSchema = Yup.object().shape({
    email: Yup.string()
      .email("please enter valide email")
      .required("email is required*"),
    password: Yup.string()
      .required("password is required*")
      .min(8, "password is too short - should be 8 characters minimum."),
  });

  const navigateToHome = () => {
    navigate("/");
  };
  const onsubmit = (values, props) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/signin`,
        {
          email: values.email,
          password: values.password,
        },
        {
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        props.setSubmitting(false);
        const token = res.data.data.access.token;
        localStorage.setItem("access_token", token);
        const decoded = jwtDecode(token);

        if (decoded.is_verified == 0) {
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/api/email/verify/resend`,
              {},
              {
                headers: {
                  Accept: "application/json",
                  "content-type": "application/json",
                  Authorization: "Bearer " + token,
                },
              }
            )
            .then((res) => {
              const data = { email: values.email };
              navigate("/email-verify", { state: data });
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (decoded.is_subscribed == 0) {
          navigate("/pricing-section");
        } else if (decoded.role) {
          decoded.role == "agency"
            ? navigate("/agency/dashboard")
            : decoded.role == "investor"
            ? navigate("/investor/dashboard")
            : decoded.role == "client"
            ? navigate("/client/dashboard")
            : navigate("/signin");
        }
      })
      .catch((error) => {
        console.log(error);
        props.setSubmitting(false);

        if (error?.code === "ERR_NETWORK") {
          setErrorMessage(error.message);
        } else if (error?.response?.status === 401) {
          setErrorMessage("Invalid Credentials");
        } else if (error?.response?.status === 404) {
          setErrorMessage("Email Not Found");
        } else {
          setErrorMessage("Something Went wrong");
        }
      });
  };
  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />

          <Grid
            item
            xs={false}
            sm={4}
            md={6}
            sx={{
              backgroundColor: "#0f2c4e",
              backgroundImage: `url(${publicUrl + "/assets/img/logo1.png"})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          />

          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            component={Paper}
            elevation={6}
            square
          >
            <Grid container justifyContent="flex-end" padding={2}>
              <Grid item>
                <CloseIcon
                  style={{
                    fill: "#0072ea",
                    justifyContent: "end",
                    cursor: "pointer",
                  }}
                  onClick={navigateToHome}
                />
              </Grid>
            </Grid>

            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 2,
              }}
            >
              {isDesktop ? (
                <Avatar sx={{ m: 1, bgcolor: "#015a80" }}>
                  <LockOutlinedIcon />
                </Avatar>
              ) : (
                <img
                  src={publicUrl + "/assets/img/logo1.png"}
                  width={100}
                  height={100}
                />
              )}
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              {/* component="form" onSubmit={handleSubmit} */}
              <Box sx={{ mt: 1 }}>
                {errorMessage ? (
                  <Alert variant="outlined" severity="error">
                    {errorMessage}
                  </Alert>
                ) : (
                  ""
                )}

                {msg ? (
                  <Alert variant="outlined" severity="error">
                    {msg}
                  </Alert>
                ) : (
                  ""
                )}
                <Formik
                  initialValues={initialValues}
                  onSubmit={onsubmit}
                  validationSchema={SigninSchema}
                  validateOnBlur={false}
                >
                  {(props) => (
                    <Form>
                      <Field
                        as={TextField}
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        helperText={<ErrorMessage name="email" />}
                      />

                      <Link
                        to="/forget-password"
                        className="float-right"
                        style={{
                          textDecoration: "none",
                          color: "#0077c0",
                          display: "block",
                          marginBottom: "-10px",
                          justifyContent: "end",
                        }}
                      >
                        Forgot password?
                      </Link>

                      <Field
                        as={TextField}
                        margin="normal"
                        fullWidth
                        name="password"
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        id="password"
                        autoComplete="current-password"
                        helperText={<ErrorMessage name="password" />}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={props.isSubmitting}
                        sx={{
                          mt: 3,
                          mb: 2,
                          ":hover": {
                            bgcolor: "#0f2c4e", // "primary.main" theme.palette.primary.main
                            color: "white",
                          },
                        }}
                      >
                        Sign In
                      </Button>

                      {props.isSubmitting ? (
                        <LinearProgress
                          sx={{
                            mt: 1,
                            mb: 2,
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Form>
                  )}
                </Formik>
                <Grid container sx={{ justifyContent: "center" }}>
                  <Grid item>
                    <span style={{ color: "gray" }} className="mx-2">
                      Don't have an account?
                    </span>
                    <Link
                      to="/signup"
                      style={{ textDecoration: "underline", color: "#0077c0" }}
                    >
                      Sign Up
                    </Link>
                  </Grid>

                  <Grid container justifyContent="center" marginTop={3}>
                    <Grid item></Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Grid
                      item
                      sx={{
                        color: "gray",
                      }}
                    >
                      <div className="google-btn">
                        <LoginSocialGoogle
                          client_id={
                            "159336827055-ruhn6m3ls7rr2f2p8fgup6oea7ofmeaf.apps.googleusercontent.com"
                          }
                          scope="openid profile email"
                          discoveryDocs="claims_supported"
                          onResolve={({ provider, data }) => {
                            localStorage.setItem("role", "agency");
                            localStorage.setItem("email", data.email);
                            localStorage.setItem("name", data.name);
                            localStorage.setItem(
                              "access_token",
                              data.access_token
                            );
                            navigate("/agency/dashboard");
                          }}
                          onReject={(err) => {}}
                        >
                          {/* <SocialIcon url="#" /> */}
                          <GoogleLoginButton
                            style={{
                              color: "gray",
                              fontSize: 13,
                              boxShadow: "rgba(0, 0, 0, 0.3) 1px 1px 2px",
                            }}
                          />
                        </LoginSocialGoogle>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}
