import { Navigate } from "react-router-dom";
import React from "react";
import { jwtDecode } from "jwt-decode";
export const ProtectedRoute = ({ children }) => {
  let role = "";
  if (localStorage.getItem("access_token")) {
    const token = localStorage.getItem("access_token");
    if (token) {
      const decode = jwtDecode(token);
      role = decode.role;
    }
  }

  if (!localStorage.getItem("access_token") && !role) {
    return <Navigate to="/signin" replace />;
  }

  return children;
};
