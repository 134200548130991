import React, { Component, useEffect, useState } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import Stack from "@mui/joy/Stack";
import { usePaymentInputs } from "react-payment-inputs";
import { useFormContext, Controller } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { jwtDecode } from "jwt-decode";
import {
  TextField,
  InputAdornment,
  Grid,
  FilledInput,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import { useCreditCardValidator, images } from "react-creditcard-validator";
import { BorderColor } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
export default function PricingSection() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const data = location.state;
  let publicUrl = process.env.PUBLIC_URL + "/";
  useEffect(() => {
    document.title = "Pricing";
  }, []);
  let imagealt = "image";
  // let data = sectiondata.pricing;
  const navigate = useNavigate();
  const [pricingData, setPricingData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [pay, setPay] = useState(false);

  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvc, setCvc] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPaymentMessage, setErrorPaymentMessage] = useState("");
  const expriy_format = (value) => {
    const expdate = value;
    const expDateFormatter =
      expdate.replace(/\//g, "").substring(0, 2) +
      (expdate.length > 2 ? "/" : "") +
      expdate.replace(/\//g, "").substring(2, 4);

    return expDateFormatter;
  };

  const cc_format = (value) => {
    const v = value.replace(/[^0-9]/gi, "").substr(0, 16);

    const parts = [];
    for (let i = 0; i < v.length; i += 4) {
      parts.push(v.substr(i, 4));
    }
    return parts.length > 1 ? parts.join(" - ") : value;
  };

  const {
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getCardImageProps,
    meta: { erroredInputs },
  } = useCreditCardValidator();

  function onSelectOption(e) {
    setSelectedOption(e.target.value);
  }
  function resetCloseForm() {
    setSelectedOption("");
    setErrorPaymentMessage("");
    setCardName("");
    setCardNumber("");
    setExpiryDate("");
    setCvc("");
    setPay(false);
    setOpen(false);
  }

  const submitSubscription = (subscriptionID) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/assignSubscription`,
        {
          subscription: subscriptionID,
        },
        {
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      .then((response) => {
        localStorage.setItem("access_token", response.data.data.access.token);
        const token = response.data.data.access.token;
        const decoded = jwtDecode(token);
        // localStorage.setItem("role", decoded.role);

        const role = decoded.role;
        {
        }
        role == "investor"
          ? navigate("/investor/dashboard")
          : navigate("/client/dashboard");
      })
      .catch((error) => {
        setErrorMessage("Something went wrong , please try again");
      });
  };

  useEffect(() => {
    console.log(data);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/subscriptions`, {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
      .then((response) => {
        setLoading(false);

        setPricingData(response.data.data.subscriptions);
        localStorage.setItem("access_token", response.data.data.access.token);
        console.log(pricingData);
      })
      .catch((error) => {});
  }, []);
  console.log();
  // pd-bottom-30
  return (
    <div className="pricing-list-area m-0">
      <div className="d-flex justify-content-center align-items-center">
        <img
          src={publicUrl + "/assets/img/logo1.png"}
          width={150}
          height={100}
        ></img>{" "}
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ fontSize: "37px", color: "#015a80" }}
        >
          Our Plans
        </div>
      </div>

      <div className="container">
        {errorMessage ? (
          <Alert variant="outlined" severity="error">
            {errorMessage}
          </Alert>
        ) : (
          ""
        )}

        <div className="section-title text-center">
          {/* <h2 className="title">{data.tile}</h2> */}
        </div>
        <div className="row">
          {pricingData?.length != 0
            ? pricingData.map((item, i) => (
                <div key={i} className="col-lg-4 col-sm-6">
                  <div className="single-pricing text-center m-0">
                    {/* className="thumb double-img" */}
                    <div>
                      {/* <img src={publicUrl + item.icon} alt={imagealt} /> */}
                      {/* <HomeWorkIcon style={{ fill: '#2f2960', fontSize: '50px' }} /> */}
                    </div>
                    <div className="details">
                      <h4 className="title" style={{ color: "#015a80" }}>
                        {item.name}
                      </h4>
                      <h3 className="price" style={{ color: "#7bb0ca" }}>
                        {item.price} {item.currency}
                      </h3>
                      <h6>Every Year</h6>
                      <ul>
                        {item.packages.map((featuresitem, i) => (
                          <li key={i}>{featuresitem.description}</li>
                        ))}
                        {/* <p>Rank booster</p>
                      <p>Lead capturing buttons
                      </p>
                      <p>Connect your social media</p>
                      <p>Display your contact info</p> */}
                      </ul>
                      <button
                        onClick={() =>
                          i == 0 ? setOpen(true) : submitSubscription(item.id)
                        }
                        className="btn btn-primary"
                        style={{
                          backgroundImage:
                            "linear-gradient(to right, #7bb0ca 0%, #015a80 100%)",
                        }}
                        href=""
                      >
                        Buy Plan
                      </button>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      <React.Fragment>
        <Modal open={open} onClose={() => resetCloseForm()}>
          <ModalDialog>
            <DialogTitle>
              {!pay ? (
                <div className="d-flex justify-content-between">
                  {" "}
                  <div>Payment</div>{" "}
                  <div style={{ marginLeft: "170px", cursor: "pointer" }}>
                    <CloseIcon onClick={() => resetCloseForm()} />
                  </div>
                </div>
              ) : (
                ""
              )}

              {pay && selectedOption == "visa" ? (
                <div className="d-flex justify-content-between">
                  <div>Visa Information</div>
                  <div style={{ marginLeft: "90px", cursor: "pointer" }}>
                    <CloseIcon onClick={() => resetCloseForm()} />
                  </div>
                </div>
              ) : (
                ""
              )}
              {pay && selectedOption == "mastercard" ? (
                <>
                  <div className="d-flex justify-content-between">
                    <div>Master Card Information</div>{" "}
                    <div style={{ marginLeft: "50px", cursor: "pointer" }}>
                      <CloseIcon onClick={() => resetCloseForm()} />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </DialogTitle>
            {/* <div className='d-flex'><div className='d-flex'><img src={publicUrl + "/assets/img/payment/master.svg"} height={30} width={50} /> <span>Master Card</span></div></div> */}
            {/* <DialogContent>
            <hr />
          </DialogContent> */}
            {!pay ? (
              <form
                onSubmit={(event) => {
                  event.preventDefault();

                  setPay(true);
                  // setOpen(false);
                }}
              >
                <Stack spacing={2}>
                  <div className="cc-selector text-center">
                    <input
                      id="visa"
                      type="radio"
                      name="credit-card"
                      value="visa"
                      checked={selectedOption == "visa"}
                      onChange={onSelectOption}
                    />
                    <label className="drinkcard-cc visa" htmlFor="visa"></label>
                    <input
                      id="mastercard"
                      type="radio"
                      name="credit-card"
                      value="mastercard"
                      checked={selectedOption == "mastercard"}
                      onChange={onSelectOption}
                    />
                    <label
                      className="drinkcard-cc mastercard"
                      htmlFor="mastercard"
                    ></label>
                  </div>
                  <FormControl>
                    <div className="text-center">
                      <span>Billing Cycle </span>{" "}
                      <input
                        id="billing-cycle"
                        type="radio"
                        name="billing-cycle"
                        value="billing-cycle"
                        checked
                        readOnly
                      />{" "}
                      <label>yearly</label>
                    </div>

                    {/*                
                <FormLabel>Name</FormLabel>
                <Input autoFocus required /> */}
                  </FormControl>
                  <FormControl>
                    <div className="bg-light p-3">
                      <div className="d-flex justify-content-between">
                        <div>SubTotal</div>
                        <div>50$</div>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div>Total</div>
                        <div style={{ color: "blue" }}>50$</div>
                      </div>
                    </div>
                  </FormControl>
                  <Button
                    type="submit"
                    disabled={selectedOption ? false : true}
                  >
                    Pay
                  </Button>
                </Stack>
              </form>
            ) : (
              <form
                onSubmit={(event) => {
                  console.log(selectedOption);
                  event.preventDefault();
                  setPay(true);

                  axios
                    .post(
                      `${process.env.REACT_APP_API_URL}/api/assignSubscription`,
                      {
                        payment_brand: selectedOption,
                        billing_cycle: "yearly",
                        card_holder: cardName,
                        card_number: cardNumber.split(" - ").join(""),
                        card_expiryMonth: expiryDate.split("/")[0],
                        card_expiryYear: expiryDate.split("/")[1].slice(0, 2),
                        card_cvv: cvc,
                        subscription: 1,
                      },
                      {
                        headers: {
                          Accept: "application/json",
                          "content-type": "application/json",
                          Authorization:
                            "Bearer " + localStorage.getItem("access_token"),
                        },
                      }
                    )
                    .then((response) => {
                      console.log(response);

                      localStorage.setItem(
                        "access_token",
                        response.data.data.access.token
                      );
                      const token = response.data.data.access.token;
                      const decoded = jwtDecode(token);
                      // localStorage.setItem("role", decoded.role);

                      navigate("/agency/dashboard");
                    })
                    .catch((error) => {
                      console.log(error);
                      if (error?.response?.data) {
                        setErrorPaymentMessage(error?.response?.data?.message);
                      } else {
                        setErrorPaymentMessage(
                          "Something Went Wrong, try again"
                        );
                      }
                    });
                  // setOpen(false);
                }}
              >
                <div className="container">
                  {errorPaymentMessage ? (
                    <div className="my-2">
                      <Alert variant="outlined" severity="error">
                        {errorPaymentMessage}
                      </Alert>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="card-input-group">
                    <div>
                      {" "}
                      <label>Holder Name</label>
                    </div>
                    <div>
                      {" "}
                      <input
                        type="text"
                        placeholder="enter the holder name"
                        className="p-1 rounded"
                        onChange={(e) => setCardName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="card-input-group mt-2">
                    {/* <svg {...getCardImageProps({ images })} /> */}
                    <div>
                      <label>Card Number</label>
                    </div>
                    {/* {...getCardNumberProps()} */}
                    {/* value={cc_format(cardNumber)}  */}
                    {/* {...getCardNumberProps()} */}
                    <div>
                      <input
                        className="p-1 rounded"
                        value={cc_format(cardNumber)}
                        onChange={(e) => setCardNumber(e.target.value)}
                        data-mask="0000 0000 0000 0000"
                        placeholder="XXXX-XXXX-XXXX-XXXX"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <small>
                      {erroredInputs.cardNumber && erroredInputs.cardNumber}
                    </small>
                  </div>

                  <div className="card-input-group mt-2">
                    <div>
                      <label>Valid Till</label>
                    </div>
                    <div>
                      <input
                        {...getExpiryDateProps()}
                        className="p-1 rounded"
                        onChange={(e) => setExpiryDate(e.target.value)}
                        value={expriy_format(expiryDate)}
                        required
                      />
                    </div>
                    <div>
                      <small>
                        {erroredInputs.expiryDate && erroredInputs.expiryDate}
                      </small>
                    </div>
                  </div>

                  <div className="card-input-group mt-2">
                    <div>
                      {" "}
                      <label>CVC</label>
                    </div>
                    <div>
                      <input
                        {...getCVCProps()}
                        className="p-1 rounded"
                        onChange={(e) => setCvc(e.target.value)}
                        required
                      />
                    </div>
                    <div>
                      <small>{erroredInputs.cvc && erroredInputs.cvc}</small>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center mt-3 ">
                    <Button
                      type="submit"
                      disabled={selectedOption ? false : true}
                      className="col-11"
                    >
                      Pay
                    </Button>
                  </div>
                </div>
                {/*                
                <FormLabel>Name</FormLabel>
                <Input autoFocus required /> */}
              </form>
            )}
          </ModalDialog>
        </Modal>
      </React.Fragment>
    </div>
  );
}

// export default PricingSection
