import { Link } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";

export const MenuItem = ({ route, literal, Icon, selected, onClick }) => {
  const link = (
    <ListItem
      button
      selected={selected}
      sx={{
        "&.Mui-selected": {
          backgroundColor: `linear-gradient(90deg,rgba(59, 125, 221, .1),(59, 125, 221, .088)50%,transparent) !important`,
          color: `#e9ecef !important`,
          borderLeft: `3px solid #3b7ddd  !important`,
        },
        "&:hover": {
          backgroundColor: `linear-gradient(90deg, rgba(59, 125, 221, .1), rgba(59, 125, 221, .088) 50% transparent) !important`,
          color: `#e9ecef !important`,
        },
      }}
      onClick={onClick}
    >
      <ListItemIcon
        sx={[
          { minWidth: "auto" },
          (theme) => ({
            paddingRight: theme.spacing(2),
          }),
        ]}
      >
        <Icon sx={{ color: "gray" }} />
      </ListItemIcon>
      <ListItemText primary={literal} />
    </ListItem>
  );

  return route ? <Link to={route}>{link}</Link> : link;
};
