import React, { useState } from "react";
import Sidebar from "../../sidebar";
import Navbar from "../../navbar";
import AgencyDashboard from "./agency-dashboard-page";
import Footer from "../../footer";
import InvestorDashboard from "./investor-dashboard";
import ClientDashboard from "./client-dashboard";

import { Header } from "../layout/Header";
import { Drawer } from "../layout/Drawer";
import { Main } from "../layout/Main";
import { jwtDecode } from "jwt-decode";
import { DrawerContextProvider } from "../../contexts/drawer-context";

function Dashboard() {
  const token = localStorage.getItem("access_token");
  const decode = jwtDecode(token);
  const role = decode.role;
  const [isOpen, setIsOpen] = useState(true);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    // <div className={isOpen ? "open wrapper" : "wrapper"}>
    //   {isOpen ? <Sidebar /> : ""}
    //   <div className="main">
    //     <Navbar toggleSidebar={toggleSidebar} />

    //     <main className="content">
    //       <div className="container-fluid p-0">
    //         {role == "agency" ? (
    //           <AgencyDashboard />
    //         ) : role == "investor" ? (
    //           <InvestorDashboard />
    //         ) : (
    //           <ClientDashboard />
    //         )}
    //       </div>
    //     </main>
    //     <Footer />
    //   </div>
    // </div>

    <div>
      <DrawerContextProvider>
        <div className="wrapper">
          <Drawer />

          <div className="main">
            <Header />

            <main className="content">
              <div className="container-fluid p-0">
                {role == "agency" ? (
                  <AgencyDashboard />
                ) : role == "investor" ? (
                  <InvestorDashboard />
                ) : (
                  <ClientDashboard />
                )}
              </div>
            </main>
            <Footer></Footer>
          </div>
        </div>
      </DrawerContextProvider>
    </div>
  );
}

export default Dashboard;
