import {
  useMediaQuery,
  useTheme,
  Drawer as MuiDrawer,
  styled,
} from "@mui/material";
import { Box } from "@mui/material";
import { useDrawerContext } from "../../contexts/drawer-context";
import { MenuItemsList } from "./MenuItemsList";

const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "isOpened",
})(({ isOpened, theme }) => ({
  width: isOpened ? 240 : theme.spacing(7),
  height: "unset",
  overflow: "auto",
  transition: isOpened
    ? theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      })
    : theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
  "& .MuiDrawer-paper": {
    background: "#222e3c",
    // background: "#D8DCD6",
    position: "static",
    overflowX: "hidden",
  },
  "& .pro-sidebar-inner": {
    background: `#222e3c !important`,
  },
  "& .pro-icon-wrapper": {
    backgroundColor: "transparent !important",
  },
  "& .pro-inner-item": {
    padding: "5px 35px 5px 20px !important",
    color: "#8e969d ",
  },
  "& .pro-inner-item:hover": {
    color: "#f5f7fb !important",
    fontSize: 15,
  },
  "& .pro-menu-item.active": {
    color: "#f5f7fb !important",
  },
  "& .MuiTypography-root , & .MuiTypography-h6": {
    color: "#8e969d !important",
    fontSize: 15,
  },
  "& .MuiTypography-root:hover": {
    color: "white !important",
    fontSize: 15,
  },
  "& .MuiTypography-root.active": {
    color: "white !important",
    fontSize: 15,
  },
}));

export const Drawer = () => {
  const { isOpened, toggleIsOpened, menu } = useDrawerContext();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    // <Box
    //   sx={{
    //     "& .pro-sidebar-inner": {
    //       background: `#222e3c !important`,
    //     },
    //     "& .pro-icon-wrapper": {
    //       backgroundColor: "transparent !important",
    //     },
    //     "& .pro-inner-item": {
    //       padding: "5px 35px 5px 20px !important",
    //       color: "#8e969d ",
    //     },
    //     "& .pro-inner-item:hover": {
    //       color: "#f5f7fb !important",
    //       fontSize: 15,
    //     },
    //     "& .pro-menu-item.active": {
    //       color: "#f5f7fb !important",
    //     },
    //     "& .MuiTypography-root , & .MuiTypography-h6": {
    //       color: "#8e969d !important",
    //       fontSize: 15,
    //     },
    //     "& .MuiTypography-root:hover": {
    //       color: "white !important",
    //       fontSize: 15,
    //     },
    //     "& .MuiTypography-root.active": {
    //       color: "white !important",
    //       fontSize: 15,
    //     },
    //   }}
    // >
    <StyledDrawer
      variant={isLargeScreen ? "permanent" : "temporary"}
      open={!isLargeScreen && isOpened ? true : false}
      onClose={() => toggleIsOpened(!isOpened)}
      isOpened={isOpened}
    >
      <MenuItemsList items={menu} />
    </StyledDrawer>
    // </Box>
  );
};
