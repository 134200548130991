import React from "react";
import { Header } from "../../layout/Header";
import { Drawer } from "../../layout/Drawer";
import Footer from "../../../footer";
import { DrawerContextProvider } from "../../../contexts/drawer-context";

import { CssBaseline } from "@mui/material";
import AddPropertySection from "./add-property-section";

function AddProperty() {
  return (
    <div>
      <CssBaseline />
      <DrawerContextProvider>
        <div className="wrapper">
          <Drawer />
          <div className="main">
            <Header />
            <main className="content">
              <div className="container-fluid p-0">
                <AddPropertySection />
              </div>
            </main>
            {/* <Footer></Footer> */}
          </div>
        </div>
      </DrawerContextProvider>
    </div>
  );
}

export default AddProperty;
