import React from "react";
import { Header } from "../layout/Header";
import { Drawer } from "../layout/Drawer";
import Footer from "../../footer";
import { DrawerContextProvider } from "../../contexts/drawer-context";
import AgencyDashboardSection from "./dashboard-section";
import { CssBaseline } from "@mui/material";
function AgencyDashboard() {
  return (
    <div>
      <CssBaseline />
      <DrawerContextProvider>
        <div className="wrapper">
          <Drawer />
          <div className="main">
            <Header />
            <main className="content">
              <div className="container-fluid p-0">
                <AgencyDashboardSection />
              </div>
            </main>
            <Footer></Footer>
          </div>
        </div>
      </DrawerContextProvider>
    </div>
  );
}

export default AgencyDashboard;
