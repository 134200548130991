import React, { useState, useRef, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import { ListItemIcon, MenuItem, Typography, lighten } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { usePlacesWidget } from "react-google-autocomplete";
import "./placesAutocomplete.css";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
function AgencyPropertiesSection() {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [openConfirmDeletePopup, setOpenConfirmDeletePopup] = useState(false);
  // const autoCompleteRef = useRef();
  // const inputRef = useRef();
  // const options = {};
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyD6Tb1IapUrBH4bUXVl778M6WSOo4K_vko",
    onPlaceSelected: (place) => {
      console.log(place.formatted_address);
      setCityTownValue(place.formatted_address);
    },
    options: {
      types: ["(regions)"],
      componentRestrictions: { country: "LB" },
    },
  });
  const [data, setProperties] = useState([]);
  const [initialPropertiesValue, setInitialPropertiesValue] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isErrorLoading, setErrorLoading] = useState(false);
  const [investorNameList, setInvestorNameList] = useState([]);
  const [propertyID, setPropertyID] = useState();
  const deleteProperty = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/property/delete/${propertyID}`,
        {
          headers: {
            Accept: "application/json",
            Content_Type: "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      .then((res) => {
        setProperties(data.filter((single) => single.id !== propertyID));
        setOpenConfirmDeletePopup(false);
        // closeMenu();
        // setIsLoadingData(false);
      })
      .catch((error) => {
        // setIsLoadingData(false);
        setErrorLoading(true);
        // closeMenu();
        console.log(error);
      });
  };

  useEffect(() => {
    document.title = "agency properties";
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/investors/names`, {
        headers: {
          Accept: "application/json",
          Content_Type: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
      .then((res) => {
        console.log(res.data.data.investors);
        setInvestorNameList(res.data.data.investors);
      })
      .catch((error) => {
        setIsLoadingData(false);
        setErrorLoading(true);
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/place-types`, {
        headers: {
          Accept: "application/json",
          Content_Type: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
      .then((res) => {
        console.log(res.data.data.placeTypes);
        setPlacesTypeList(res.data.data.placeTypes);

        setIsLoadingData(false);
      })
      .catch((error) => {
        setIsLoadingData(false);
        setErrorLoading(true);
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/properties`, {
        headers: {
          Accept: "application/json",
          Content_Type: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
      .then((res) => {
        const properties = res.data.data.properties;

        // let arrayOfPlacesType = [];
        let arrayOfPropertyName = [];

        for (var i = 0; i < properties.length; i++) {
          //   if (arrayOfPlacesType.indexOf(properties[i].place_type) == -1) {
          //     arrayOfPlacesType.push(properties[i].place_type);
          //   }
          if (arrayOfPropertyName.indexOf(properties[i].property_name) == -1) {
            arrayOfPropertyName.push(properties[i].property_name);
          }
        }
        // setPlacesTypeList(arrayOfPlacesType);
        setPropertiesNameList(arrayOfPropertyName);

        setProperties(res.data.data.properties);
        setInitialPropertiesValue(res.data.data.properties);

        setIsLoadingData(false);
      })
      .catch((error) => {
        setIsLoadingData(false);
        setErrorLoading(true);
        console.log(error);
      });
  }, []);
  function getPropertiesName(placeType) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/properties/${placeType.id}/properties`,
        {
          headers: {
            Accept: "application/json",
            Content_Type: "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      .then((res) => {
        console.log(res.data.data.properties);
        setPropertiesNameList(res.data.data.properties);
      })
      .catch((error) => {
        // setIsLoadingData(false);
        // setErrorLoading(true);
        console.log(error);
      });
  }
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [isLoadingFilter, setLoadingFilter] = useState(false);
  const [selectedAvailableProperty, setSelectedAvailableProperty] =
    useState(false);
  const [selectedInvestedProperty, setSelectedInvestedProperty] =
    useState(false);
  const [investorName, setInvestorName] = useState("");
  // const [cityTownvalue, setCityTownValue] = useState(null);
  const [propertyNamevalue, setPropertyNameValue] = useState(null);
  const [placeTypevalue, setPlaceTypeValue] = useState();
  const [nbOfBedrooms, setNbOfBedrooms] = useState(null);
  const [minimumPrice, setMinimumPrice] = useState("");
  const [maximumPrice, setMaximumPrice] = useState("");
  const [cityTownvalue, setCityTownValue] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  // const clearAutoCompleteInput = () => {
  //   const allInputs = ref.current.querySelectorAll("input");
  //   allInputs.forEach((singleInput) => (singleInput.value = ""));
  // };
  function Filter() {
    setLoadingFilter(true);
    console.log(investorName);
    console.log(selectedAvailableProperty);
    console.log(selectedInvestedProperty);
    console.log(cityTownvalue?.value?.description);
    console.log(fromDate);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/properties/filter`,

        {
          headers: {
            Accept: "application/json",
            Content_Type: "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
          params: {
            city_town: cityTownvalue?.value?.description
              ? cityTownvalue?.value?.description
              : undefined,
            property_name: propertyNamevalue ? propertyNamevalue : undefined,
            available: selectedAvailableProperty,
            place_type: placeTypevalue?.id ? placeTypevalue?.id : undefined,
            investor_username: investorName ? investorName : undefined,
            price_min: minimumPrice ? minimumPrice : undefined,
            price_max: maximumPrice ? maximumPrice : undefined,
            bedrooms: nbOfBedrooms ? nbOfBedrooms : undefined,
            created_at_from: fromDate ? fromDate : undefined,
            created_at_to: toDate ? toDate : undefined,
          },
        }
      )
      .then((res) => {
        setLoadingFilter(false);

        setProperties(res.data.data.properties);
      })
      .catch((error) => {
        setLoadingFilter(false);
        console.log(error);
      });
  }
  function Reset() {
    // clearAutoCompleteInput();
    setPlaceTypeValue(null);
    setPropertyNameValue(null);
    setCityTownValue("");
    setNbOfBedrooms(null);
    setMinimumPrice("");
    setMaximumPrice("");
    setFromDate("");
    setToDate("");
    setProperties(initialPropertiesValue);
  }

  const [propertiesNameList, setPropertiesNameList] = useState([]);
  const [PlacesTypeList, setPlacesTypeList] = useState([]);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "code",
        enableHiding: true,
        header: "Property ID",
        size: 50,
        // Cell: ({ cell }) => (
        //   <Box component="span">{"PROP" + cell.getValue()}</Box>
        // ),
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img
              alt="avatar"
              height={30}
              src={publicUrl + "/assets/img/logo1.png"}
              loading="lazy"
              style={{ borderRadius: "50%" }}
            />
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: "property_name", //access nested data with dot notation
        header: "Property Name",
        size: 150,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {/* <img
              alt="avatar"
              height={30}
              src={publicUrl + "/assets/img/logo1.png"}
              loading="lazy"
              style={{ borderRadius: "50%" }}
            /> */}
            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },

      {
        accessorKey: "place_type.title",
        header: "Place Type",
        size: 150,
      },
      {
        accessorKey: "investor.username", //normal accessorKey
        header: "Investor Username",
        size: 200,
        Cell: ({ cell }) => (
          <Box component="span">
            {cell.getValue() == null ? "no investor" : cell.getValue()}
          </Box>
        ),
      },
      // {
      //   accessorKey: "price_of_investment",
      //   filterVariant: "range",
      //   header: "Price of Investment",
      //   size: 150,
      //   Cell: ({ cell }) => (
      //     <Box component="span">
      //       {cell.getValue()?.toLocaleString?.("en-US", {
      //         style: "currency",
      //         currency: "USD",
      //         minimumFractionDigits: 0,
      //         maximumFractionDigits: 0,
      //       })}
      //     </Box>
      //   ),
      // },
      {
        accessorKey: "price_of_investment",
        header: "Price of Investment",
        size: 150,
        Cell: (props) => (
          <>
            <span className="item currency">
              {props.row.original.currency} &nbsp;
            </span>
            <span className="item price">
              {props.row.original.price_of_investment}
            </span>
          </>
        ),
      },
      {
        accessorKey: "city_town",
        header: "City/Town",
        size: 150,
      },
      {
        accessorKey: "property_status_id",
        header: "Status",
        size: 150,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                cell.getValue() == 1
                  ? theme.palette.warning.dark
                  : cell.getValue() == 2
                  ? theme.palette.success.dark
                  : theme.palette.error.dark,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "9ch",
              p: "0.25rem",
            })}
          >
            {cell.getValue() == 1 ? "Available" : "Invested"}
          </Box>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    // initialState: { columnVisibility: { id: false } },
    enableRowActions: true,
    enableFacetedValues: true,
    // enableColumnPinning: true,
    // enableRowSelection: true,
    initialState: {
      pagination: { pageSize: 5, pageIndex: 0 },
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },

    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        console.log(row.original.id);
        // {`/admin/editAccount/${ID}`}
        // navigate("/agency/edit-property/" + row.original.id, {
        //   state: row.original,
        // });
      },
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <MenuItem
        key={0}
        onClick={() => {
          console.log(row);
          navigate("/agency/edit-property/" + row.original.id, {
            state: row.original,
          });
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        Edit Property
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          setPropertyID(row.original.id);
          setOpenConfirmDeletePopup(true);
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        Delete Property
      </MenuItem>,
    ],
    // renderDetailPanel: ({ row }) => (
    //   <Box
    //     sx={{
    //       alignItems: "center",
    //       display: "flex",
    //       justifyContent: "space-around",
    //       left: "30px",
    //       maxWidth: "1000px",
    //       position: "sticky",
    //       width: "100%",
    //     }}
    //   >
    //     <img
    //       alt="avatar"
    //       height={200}
    //       src={publicUrl + "/assets/img/logo1.png"}
    //       loading="lazy"
    //       style={{ borderRadius: "50%" }}
    //     />
    //     <Box sx={{ textAlign: "center" }}>
    //       <Typography variant="h3">Address Property</Typography>
    //       <Typography variant="h4">{row.original.address}</Typography>
    //     </Box>
    //   </Box>
    // ),
  });

  return (
    <>
      {isLoadingData ? (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <h4 style={{ color: "gray" }}>Show Properties</h4>

          <div className="d-flex justify-content-end py-3">
            <div className="d-flex">
              <div>
                {" "}
                {!showFilter ? (
                  <FilterAltOutlinedIcon
                    style={{ cursor: "pointer" }}
                    fontSize="large"
                    color="action"
                    onClick={toggleFilter}
                  />
                ) : (
                  <FilterAltOffOutlinedIcon
                    style={{ cursor: "pointer" }}
                    fontSize="large"
                    color="action"
                    onClick={toggleFilter}
                  />
                )}
              </div>

              <div className="mx-2">
                <Button
                  variant="outlined"
                  onClick={() => navigate("/agency/add-property")}
                >
                  Add New Property
                </Button>
              </div>
            </div>
          </div>
          {showFilter ? (
            <div className="mb-5">
              <div className="row my-3">
                <div className="col-12">
                  <span style={{ color: "gray" }}>
                    Show Available/Invested Properties?
                  </span>
                  <div className="d-flex">
                    <div className="" style={{ color: "gray" }}>
                      <FormControlLabel
                        label="Available"
                        control={
                          <Checkbox
                            checked={selectedAvailableProperty}
                            onChange={(e) => {
                              setSelectedAvailableProperty(e.target.checked);
                              if (!selectedInvestedProperty) {
                                setInvestorName("");
                              }
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        label="Invested"
                        control={
                          <Checkbox
                            checked={selectedInvestedProperty}
                            onChange={(e) =>
                              setSelectedInvestedProperty(e.target.checked)
                            }
                          />
                        }
                      />
                    </div>
                    <div>
                      {selectedInvestedProperty ? (
                        // <input
                        //   type="text"
                        //   placeholder="investor name"
                        //   className="border rounded p-2"
                        //   label="Investor Name"
                        //   disabled={
                        //     selectedAvailableProperty &&
                        //     !selectedInvestedProperty
                        //   }
                        //   value={investorName}
                        //   onChange={(e) => setInvestorName(e.target.value)}
                        //   style={{ width: 200 }}
                        // />

                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={investorNameList.map(
                            (investor) => investor.username
                          )}
                          // value={investorName}
                          onChange={(event, newValue) => {
                            console.log(newValue);
                            setInvestorName(newValue);
                          }}
                          sx={{
                            display: "inline-block",
                            "& input": {
                              width: 200,
                              bgcolor: "background.paper",
                              color: (theme) =>
                                theme.palette.getContrastText(
                                  theme.palette.background.paper
                                ),
                            },
                          }}
                          renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                              <input
                                type="text"
                                {...params.inputProps}
                                className="border rounded p-1"
                                placeholder="investor name"
                                disabled={
                                  selectedAvailableProperty &&
                                  !selectedInvestedProperty
                                }
                              />
                            </div>
                          )}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* City/Town */}
                <div className="col-lg-3 col-12 ">
                  <label style={{ color: "gray", margin: 0 }}>
                    City/Town:&nbsp;
                  </label>
                  <input
                    ref={ref}
                    style={{ width: "200px" }}
                    className="border rounded p-1"
                  />
                  {/* <GooglePlacesAutocomplete
                    apiKey="AIzaSyD6Tb1IapUrBH4bUXVl778M6WSOo4K_vko"
                    style={{ width: "100px" }}
                    selectProps={{
                      placeholder: "city/town",
                      cityTownvalue,
                      onChange: setCityTownValue,
                    }}
                  /> */}
                  {/* 
                  <button
                    onClick={console.log(cityTownvalue?.value.description)}
                  >
                    press
                  </button> */}
                </div>
                {/* Place Type */}
                <div className="col-lg-3 col-12">
                  <label style={{ color: "gray" }}>
                    Place Type: <br />
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      // options={ PlacesTypeList.map( ( placetype ) => placetype.title ) }
                      options={PlacesTypeList.map(
                        (placetype) => placetype.title
                      )}
                      // value={placeTypevalue}
                      onChange={(event, newValue) => {
                        const placeType = PlacesTypeList.filter(
                          (placetype) => placetype.title == newValue
                        );
                        console.log(placeType);
                        setPlaceTypeValue(placeType[0]);
                        console.log(placeTypevalue);
                        getPropertiesName(placeType[0]);
                      }}
                      sx={{
                        display: "inline-block",
                        "& input": {
                          width: 200,
                          bgcolor: "background.paper",
                          color: (theme) =>
                            theme.palette.getContrastText(
                              theme.palette.background.paper
                            ),
                        },
                      }}
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <input
                            type="text"
                            {...params.inputProps}
                            className="border rounded p-1"
                          />
                        </div>
                      )}
                    />
                  </label>
                </div>
                {/* property name */}
                <div className="col-lg-3 col-12">
                  <label style={{ color: "gray" }}>
                    Property Name : <br />
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={propertiesNameList}
                      value={propertyNamevalue}
                      onChange={(event, newValue) => {
                        setPropertyNameValue(newValue);
                      }}
                      sx={{
                        display: "inline-block",
                        "& input": {
                          width: 200,
                          bgcolor: "background.paper",
                          color: (theme) =>
                            theme.palette.getContrastText(
                              theme.palette.background.paper
                            ),
                        },
                      }}
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <input
                            type="text"
                            {...params.inputProps}
                            className="border rounded p-1"
                          />
                        </div>
                      )}
                    />
                  </label>
                </div>

                {/* number of Bedrooms */}
                <div className="col-lg-3 col-12">
                  <label style={{ color: "gray" }}>
                    Nb Of Bedrooms: <br />
                    <input
                      type="number"
                      className="border rounded bg-white p-1"
                      style={{ width: 200 }}
                      value={nbOfBedrooms}
                      onChange={(event) => {
                        setNbOfBedrooms(event.target.value);
                      }}
                    />
                    {/* <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={["1", "2", "3", "4", "5", "6"]}
                      value={nbOfBedrooms}
                      onChange={(event, newValue) => {
                        setNbOfBedrooms(newValue);
                      }}
                      sx={{
                        display: "inline-block",
                        "& input": {
                          width: 200,
                          bgcolor: "background.paper",
                          color: (theme) =>
                            theme.palette.getContrastText(
                              theme.palette.background.paper
                            ),
                        },
                      }}
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <input
                            min={0}
                            max={7}
                            type="number"
                            {...params.inputProps}
                            className="border rounded p-1"
                          />
                        </div>
                      )}
                    /> */}
                  </label>
                </div>
              </div>

              <div className="row my-3">
                <div className="col-lg-3 col-12">
                  {" "}
                  <label style={{ color: "gray" }}>
                    From:
                    <br />
                    <input
                      type="date"
                      className="p-1 border"
                      style={{ color: "gray", width: 200 }}
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />{" "}
                  </label>
                </div>

                <div className="col-lg-3 col-12">
                  <label style={{ color: "gray" }}>
                    To:
                    <br />
                    <input
                      type="date"
                      className="p-1 border"
                      style={{ color: "gray", width: 200 }}
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </label>
                </div>

                <div className="col-lg-3 col-12">
                  <label style={{ color: "gray" }}>
                    Minimum Price: <br />
                    <input
                      type="number"
                      className="border rounded bg-white p-1"
                      style={{ width: 200 }}
                      value={minimumPrice}
                      onChange={(event) => {
                        setMinimumPrice(event.target.value);
                      }}
                    />
                  </label>
                </div>

                <div className="col-lg-3 col-12">
                  <label style={{ color: "gray" }}>
                    Maximum Price: <br />
                    <input
                      type="number"
                      className="border rounded bg-white p-1"
                      style={{ width: 200 }}
                      value={maximumPrice}
                      onChange={(event) => {
                        setMaximumPrice(event.target.value);
                      }}
                    />
                  </label>
                </div>
              </div>

              <div className="d-flex justify-content-end mx-5">
                <div className="mx-3">
                  <Button variant="outlined" className="mx-2" onClick={Reset}>
                    reset
                  </Button>
                  <Button
                    variant="contained"
                    disabled={
                      (!propertyNamevalue &&
                        !placeTypevalue &&
                        !selectedAvailableProperty &&
                        !selectedInvestedProperty &&
                        !cityTownvalue &&
                        !nbOfBedrooms &&
                        !minimumPrice &&
                        !maximumPrice &&
                        !fromDate &&
                        !toDate) ||
                      isLoadingFilter
                    }
                    onClick={Filter}
                  >
                    Filter
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
          <div className="my-3">
            {isLoadingFilter ? (
              <div className="d-flex justify-content-center">
                <CircularProgress />
              </div>
            ) : (
              <MaterialReactTable table={table} />
            )}
            <Modal
              aria-labelledby="modal-title"
              aria-describedby="modal-desc"
              open={openConfirmDeletePopup}
              onClose={() => setOpenConfirmDeletePopup(false)}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Sheet
                variant="outlined"
                sx={{
                  maxWidth: 500,
                  borderRadius: "md",
                  p: 4,
                  boxShadow: "lg",
                }}
              >
                <ModalClose variant="plain" sx={{ m: 1 }} />

                <Typography
                  sx={{
                    margin: 3,
                    color: "gray",
                  }}
                >
                  Are You Sure You Want To Delete A Property?
                </Typography>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-light mx-2"
                    onClick={() => setOpenConfirmDeletePopup(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-danger mx-2"
                    onClick={() => deleteProperty()}
                  >
                    Delete
                  </button>
                </div>
              </Sheet>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
}

export default AgencyPropertiesSection;
