import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DefaultImage from "../user/assets/user.jpg";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOpenSharpIcon from "@mui/icons-material/MenuOpenSharp";
import { useDrawerContext } from "../../contexts/drawer-context";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { jwtDecode } from "jwt-decode";
export const Header = () => {
  const { isOpened, toggleIsOpened } = useDrawerContext();
  const theme = useTheme();
  const [file, setFile] = useState(DefaultImage);
  const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(true);
  const token = localStorage.getItem("access_token");
  const decoded = jwtDecode(token);
  const role = decoded.role;

  const [userName, setUserName] = useState(localStorage.getItem("name"));
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/user`, {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
      .then((response) => {
        setIsLoadingUserInfo(false);
        console.log("test nav");
        setFile(
          response.data.data.user.profile_picture
            ? response.data.data.user.profile_picture
            : response.data.data.user.profile_photo_url
        );
        setUserName(response.data.data.user.username);

        // setLoading(false)

        // setPricingData(response.data.properties)
      })
      .catch((error) => {
        setIsLoadingUserInfo(false);
        console.log(error);
      });

    // Anything in here is fired on component mount.
    document.addEventListener("myEvent", () =>
      setUserName(localStorage.getItem("name"))
    );
    document.addEventListener("myPictureEvent", () =>
      setFile(localStorage.getItem("Picture"))
    );
    return () => {
      // Anything in here is fired on component unmount.
      document.removeEventListener("myEvent", () =>
        setUserName(localStorage.getItem("name"))
      );
      document.removeEventListener("myPictureEvent", () =>
        setFile(localStorage.getItem("Picture"))
      );
    };
  }, []);

  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    localStorage.removeItem("loggedIn");
    console.log("pressed log");
    navigate("/signin");
  };
  let publicUrl = process.env.PUBLIC_URL + "/";

  function handleEvent(event) {
    setUserName({ value: event.detail });
  }

  return (
    // <AppBar sx={{ backgroundColor: "white", color: "gray" }}>
    //   <Toolbar>
    //     <IconButton
    //       color="inherit"
    //       onClick={() => toggleIsOpened(!isOpened)}
    //       sx={{ padding: theme.spacing(1) }}
    //     >
    //       {isOpened ? <ChevronLeftIcon /> : <MenuIcon />}
    //     </IconButton>
    //     <Typography variant="h6" sx={{ margin: "auto" }}>
    //       Header
    //     </Typography>
    //   </Toolbar>
    // </AppBar>
    ////////////////////////////////////

    <nav className="navbar navbar-expand-lg  navbar-light bg-light ">
      <Link
        color="inherit"
        onClick={() => toggleIsOpened(!isOpened)}
        sx={{ padding: theme.spacing(1) }}
      >
        {isOpened ? <ChevronLeftIcon /> : <MenuIcon />}
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="true"
        aria-label="Toggle navigation"
      >
        <KeyboardArrowDownIcon />
      </button>
      <div className="collapse navbar-collapse" id="navbarNavDropdown">
        <ul
          className="navbar-nav navbar-align"
          style={{ visibility: "visible", opacity: 1 }}
        >
          <li className="nav-item active">
            <a className="nav-link" href="/" style={{ color: "gray" }}>
              Home <span className="sr-only">(current)</span>
            </a>
          </li>
          <li className="nav-item dropdown ">
            <a
              className="nav-link dropdown-toggle  d-sm-inline-block"
              href="#"
              data-bs-toggle="dropdown"
              style={{ paddingRight: 30 }}
            >
              <span className="text-dark mx-1">{userName}</span>
              <img
                src={file}
                className="avatar rounded-circle img-fluid rounded me-1"
                alt="Charles Hall"
              />
            </a>
            <div className="dropdown-menu dropdown-menu-end">
              <Link
                className="dropdown-item"
                to={
                  role == "agency"
                    ? "/agency/profile"
                    : role == "investor"
                    ? "/investor/profile"
                    : "/client/profile"
                }
              >
                <PermIdentityIcon style={{ color: "gray" }} /> Profile
              </Link>
              <a className="dropdown-item" href="#">
                <PieChartOutlineIcon style={{ color: "gray" }} /> Analytics
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="index.html">
                <SettingsOutlinedIcon style={{ color: "gray" }} /> Settings &
                Privacy
              </a>
              <a className="dropdown-item" href="#">
                <HelpOutlineOutlinedIcon style={{ color: "gray" }} /> Help
                Center
              </a>
              <div className="dropdown-divider"></div>
              <button className="dropdown-item" onClick={logout} role="button">
                Log out
              </button>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    //////////////////////////////////////////////////////////////////////////////////////////////////

    // <nav class="navbar navbar-expand-lg navbar-light bg-light">
    //   <a class="navbar-brand" href="#">
    //     Navbar
    //   </a>
    //   <button
    //     class="navbar-toggler"
    //     type="button"
    //     data-toggle="collapse"
    //     data-target="#navbarNavDropdown"
    //     aria-controls="navbarNavDropdown"
    //     aria-expanded="false"
    //     aria-label="Toggle navigation"
    //   >
    //     <KeyboardArrowDownIcon />
    //   </button>
    //   <div class="collapse navbar-collapse" id="navbarNavDropdown">
    //     <ul
    //       class="navbar-nav navbar-align"
    //       style={{ visibility: "visible", opacity: 1 }}
    //     >

    //       <li class="nav-item dropdown">
    //         <a
    //           class="nav-link dropdown-toggle"
    //           href="#"
    //           id="navbarDropdownMenuLink"
    //           data-toggle="dropdown"
    //           aria-haspopup="true"
    //           aria-expanded="false"
    //         >
    //           Dropdown link
    //         </a>
    //         <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
    //           <a class="dropdown-item" href="#">
    //             Action
    //           </a>
    //           <a class="dropdown-item" href="#">
    //             Another action
    //           </a>
    //           <a class="dropdown-item" href="#">
    //             Something else here
    //           </a>
    //         </div>
    //       </li>
    //     </ul>
    //   </div>
    // </nav>
  );
};

export default Header;
