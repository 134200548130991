import React, { useEffect, useState, useCallback, useRef } from "react";
import ImageUploading from "react-images-uploading";
import "./placesAutocomplete.css";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  useJsApiLoader,
  // Autocomplete,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import "./addProperty.css";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/joy/Snackbar";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import InputAdornment from "@mui/material/InputAdornment";
const defaultMapConfig = {
  gestureHandling: "greedy",
  options: {
    fullscreenControl: false,
    mapTypeControl: false,
  },
  mapContainerStyle: {
    height: "50vh",
    width: "100%",
  },
};

function EditPropertySection() {
  const [libraries] = useState(["places"]);
  const params = useParams();
  const [images, setImages] = useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };
  const [currentMarkerLng, setCurrentMarkerLng] = useState(0);
  const [currentMarkerLat, setCurrentMarkerLat] = useState(0);

  const [formattedAddress, setFormatedAddress] = useState();

  const inputRef = useRef();

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setCurrentMarkerLat(place.geometry.location.lat());
      setCurrentMarkerLng(place.geometry.location.lng());
      getAddress(place.geometry.location.lat(), place.geometry.location.lng());
    }
  };

  const getCurrentLocation = () => {
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        setCurrentMarkerLat(pos.lat);
        setCurrentMarkerLng(pos.lng);
        getAddress(pos.lat, pos.lng);
      }
    );
  };

  // const onMapLoad = (map) => {
  //   navigator?.geolocation.getCurrentPosition(
  //     ({ coords: { latitude: lat, longitude: lng } }) => {
  //       const pos = { lat, lng };
  //       setCurrentMarkerLat(pos.lat);
  //       setCurrentMarkerLng(pos.lng);
  //
  //       getAddress(pos.lat, pos.lng);
  //     }
  //   );
  // };

  function setMarkerLocation(longitude, latidude) {
    setCurrentMarkerLng(longitude);
    setCurrentMarkerLat(latidude);
  }
  const CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        name={name}
        value={value}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [placeTypevalue, setPlaceTypeValue] = useState("");
  const [PlacesTypeList, setPlacesTypeList] = useState([]);

  const [currencies, setCurrencies] = useState([]);
  const [statues, setStatues] = useState([]);
  const [property, setProperty] = useState();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isErrorLoading, setErrorLoading] = useState(false);
  const [propertyName, setPropertyName] = useState("");
  const [placeType, setPlaceType] = useState("");
  const [nbOfBedrooms, setNbOfBedrooms] = useState("");
  const [nbOfBathrooms, setNbOfBathrooms] = useState("");
  const [nbOfBalconies, setNbOfBalconies] = useState("");
  const [nbOfParking, setNbOfParking] = useState("");
  const [description, setDescription] = useState("");
  const [investorUserName, setInvestorUserName] = useState("");
  const [status, setStatus] = useState("");
  const [currency, setCurrency] = useState("");
  const [checkedLinkToInvestor, setCheckedLinkToInvestor] = useState(false);
  const [priceOfInvestment, setPriceOfInvestment] = useState("");
  const [region, setRegion] = useState("");
  const [building, setBuilding] = useState("");
  const [floor, setFloor] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [cityTown, setCityTown] = useState("");
  const [investorNameList, setInvestorNameList] = useState([]);
  const [propertyCost, setPropertyCost] = useState();
  const [propertyArea, setPropertyArea] = useState();
  const [videoUrl, setVideoUrl] = useState();
  function getAddress(latitude, longitude) {
    const geocodingApiUrl = "https://maps.googleapis.com/maps/api/geocode/json";
    const url = `${geocodingApiUrl}?latlng=${latitude},${longitude}&key=AIzaSyD6Tb1IapUrBH4bUXVl778M6WSOo4K_vko`;
    axios
      .get(url)
      .then((res) => {
        console.log(res);
        // const formatedaddress = res.data.results[2].formatted_address;
        var street = "";
        var city = "";
        var state = "";
        var postalCode = "";
        const results = res.data.results;
        console.log(results);
        for (var i = 0; i < results[0].address_components.length; i++) {
          if (results[0].address_components[i].types[0] == "locality") {
            city = results[0].address_components[i].long_name;
            setCityTown(city);
          }
          if (
            results[0].address_components[i].types[0] ==
            '"administrative_area_level_1"'
          ) {
            state = results[0].address_components[i].long_name;
            setRegion(state);
            setStateProvince(state);
          }
        }

        for (var i = 0; i < results.length; i++) {
          if (results[i].types[0] === "locality" && city == "") {
            city = results[i].address_components[0].long_name;
            setCityTown(city);
          }

          if (
            results[i].types[0] === "administrative_area_level_1" &&
            state == ""
          ) {
            state = results[i].address_components[0].long_name;
            setRegion(state);
            setStateProvince(state);
          }
          if (results[i].types[0] === "postal_code") {
            postalCode = results[i].address_components[0].long_name;
            setPostalCode(postalCode);
          }

          if (results[i].types[0] === "route") {
            for (var j = 0; j < 4; j++) {
              if (j == 0) {
                street = results[i].address_components[j].long_name;
              } else {
                street += ", " + results[i].address_components[j].long_name;
              }
            }
            setStreetAddress(street);
          }
          if (results[i].types[0] === "street_address") {
            for (var j = 0; j < 4; j++) {
              if (j == 0) {
                street = results[i].address_components[j].long_name;
              } else {
                street += ", " + results[i].address_components[j].long_name;
              }
            }
          }
          setStreetAddress(street);
        }
      })
      .catch((error) => {});
  }
  const [latitudeValue, setLatitudeValue] = useState("");
  const [longitudeValue, setLongitudeValue] = useState("");
  function setLocationInfo(props) {
    const latitude = currentMarkerLat;
    const longitude = currentMarkerLng;
    getAddress(latitude, longitude);
    props.setFieldValue("region", region);
    props.setFieldValue("state_province", region);
    props.setFieldValue("street_address", streetAddress);
    props.setFieldValue("postal_code", postalCode);
    setLongitudeValue(currentMarkerLng);
    setLatitudeValue(currentMarkerLat);
  }
  useEffect(() => {
    document.title = "edit property";
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/investors/names`, {
        headers: {
          Accept: "application/json",
          Content_Type: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
      .then((res) => {
        console.log(res.data.data.investors);
        setInvestorNameList(res.data.data.investors);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/place-types`, {
        headers: {
          Accept: "application/json",
          Content_Type: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
      .then((res) => {
        console.log(res.data.data.placeTypes);
        setPlacesTypeList(res.data.data.placeTypes);
      })
      .catch((error) => {
        // setIsLoadingData(false);
        setErrorLoading(true);
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/property/${params.id}`, {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
      .then((res) => {
        const property = res.data.data.property;
        navigator?.geolocation.getCurrentPosition(
          ({ coords: { latitude: lat, longitude: lng } }) => {
            const pos = { lat, lng };
            console.log(pos);
            setCurrentMarkerLat(pos.lat);
            setCurrentMarkerLng(pos.lng);

            getAddress(pos.lat, pos.lng);

            if (property?.latitude && property?.longitude) {
              setCurrentMarkerLat(Number(property.latitude));
              setCurrentMarkerLng(Number(property.longitude));
              getAddress(currentMarkerLat, currentMarkerLng);
            }
          }
        );

        setIsLoadingData(false);
        console.log("data property");
        console.log(res.data.data.property);

        setPropertyName(property.property_name);
        setPropertyCost(property.cost);
        setPropertyArea(property.property_area);
        setVideoUrl(property.video_url);
        setPlaceType(property.place_type.id);
        setNbOfBedrooms(property.bedrooms);
        setNbOfBathrooms(property.bathrooms);
        setNbOfBalconies(property.nb_of_balconies);
        setDescription(property.description);
        setInvestorUserName(property.investor_id);
        setStatus(property.status.id);
        setCurrency(property.currency);
        setCheckedLinkToInvestor(
          property.linked_to_investor == 0 ? false : true
        );
        setPriceOfInvestment(property.price_of_investment);
        setRegion(property.region);
        setBuilding(property.building);
        setFloor(property.floor);
        setStreetAddress(property.street_address);
        setStateProvince(property.state_province);
        setPostalCode(property.postal_code);
      })
      .catch((error) => {
        setIsLoadingData(false);
        setErrorLoading(true);
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/currencies`, {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
      .then((res) => {
        setCurrencies(res.data.data.currencies);
      })
      .catch((error) => {});
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/property-statuses`, {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
      .then((res) => {
        setStatues(res.data.data.statuses);
      })
      .catch((error) => {});
  }, []);

  const initialValues = {
    propertyname: propertyName,
    placetype: placeType,
    propertycost: propertyCost || "",
    video_url: videoUrl || "",
    property_area: propertyArea || "",
    bedrooms: nbOfBedrooms,
    bathrooms: nbOfBathrooms,
    balconies: nbOfBalconies,
    parkingslot: nbOfBalconies,
    description: description,
    investor_username: investorUserName || "",
    status: status,
    priceOfInvestment: priceOfInvestment,
    currency: currency,
    region: region,
    building: building,
    floor: floor,
    street_address: streetAddress,
    state_province: stateProvince,
    postal_code: postalCode,
  };

  const AddPropertySchema = Yup.object().shape({
    propertyname: Yup.string().required("property name is required*"),
    bedrooms: Yup.number().required("bedrooms number is required*"),
    bathrooms: Yup.number().required("bathrooms number is required*"),
    balconies: Yup.number().required("balconies number is required*"),
    parkingslot: Yup.number().required("parking slot number is required*"),
    description: Yup.string().required("description is required*"),
    investor_username: checkedLinkToInvestor
      ? Yup.string().required("inverstor name is required*")
      : Yup.string(),
    priceOfInvestment: Yup.number().required(
      "amount of investment  is required*"
    ),
    currency: Yup.string().required("currency is required*"),
    status: Yup.string().required("status is required*"),
  });

  const onsubmit = (values, props) => {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/property/edit/${params.id}`,
        {
          property_name: values.propertyname,
          place_type: values.placetype,
          bedrooms: values.bedrooms,
          bathrooms: values.bathrooms,
          nb_of_balconies: values.balconies,
          nb_of_parking_slots: values.parkingslot,
          description: values.description,
          linked_to_investor: checkedLinkToInvestor,
          // investor_username: values.investor_username,
          investor_id: values.investor_username,
          currency: values.currency,
          price_of_investment: values.priceOfInvestment,
          status: values.status,
          city_town: cityTown,
          region: values.region,
          building: values.building,
          floor: values.floor,
          state_province: values.state_province,
          postal_code: values?.postal_code ? values?.postal_code : undefined,
          street_address: values.street_address,
          latitude: latitudeValue,
          longitude: longitudeValue,
        },
        {
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      .then((res) => {
        props.setSubmitting(false);
        setOpenSuccessAlert(true);
      })
      .catch((error) => {
        setOpenErrorAlert(true);
        props.setSubmitting(false);
      });
  };

  return (
    <>
      {isLoadingData ? (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <h4 style={{ color: "gray" }} className="ml-3">
            Edit Property
          </h4>
          <div>
            {/* <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={maxNumber}
          dataURLKey="data_url"
          acceptType={["jpg", "png"]}
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            // write your building UI
            <div className="upload__image-wrapper">
              <button
                style={isDragging ? { color: "red" } : null}
                onClick={onImageUpload}
                {...dragProps}
              >
                Click or Drop here
              </button>
              &nbsp;
              <button onClick={onImageRemoveAll}>Remove all images</button>
              <div className="row">
                {imageList.map((image, index) => (
                  <div key={index} className="col-2">
                    <img src={image.data_url} alt="" width="100" />
                    <div className="d-flex" style={{ width: "100px" }}>
                      <button onClick={() => onImageUpdate(index)}>
                        Update
                      </button>
                      <button onClick={() => onImageRemove(index)}>
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </ImageUploading> */}
          </div>
          <div className="p-3">
            <h6 style={{ marginBottom: -34 }}>1. Property Details Section:</h6>
            <Formik
              initialValues={initialValues}
              onSubmit={onsubmit}
              validationSchema={AddPropertySchema}
              validateOnBlur={false}
            >
              {(props) => (
                <Form>
                  <div className="row my-5">
                    <div className="col-lg-2 col-12">
                      <Field
                        as={TextField}
                        margin="normal"
                        variant="standard"
                        fullWidth
                        id="propertyname"
                        label="Property Name"
                        name="propertyname"
                        autoComplete="propertyname"
                        helperText={<ErrorMessage name="propertyname" />}
                      />
                    </div>
                    <div className="col-lg-2 col-12">
                      <FormControl
                        variant="standard"
                        sx={{ mt: 2, p: 0, minWidth: 162 }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Place Type
                        </InputLabel>
                        <Field
                          name="placetype"
                          component={CustomizedSelectForFormik}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {PlacesTypeList.map((item) => (
                            <MenuItem value={item.id}>{item.title}</MenuItem>
                          ))}

                          {/* <MenuItem value={"Villa"}>Villa</MenuItem>
                          <MenuItem value={"Condo"}>Condo</MenuItem>
                          <MenuItem value={"Apartment"}>Apartment</MenuItem> */}
                        </Field>

                        <ErrorMessage name="placetype">
                          {(msg) => (
                            <div
                              className="font-italic"
                              style={{ color: "#871616", fontSize: "12px" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </FormControl>
                    </div>
                    <div className="col-lg-2 col-12">
                      <Field
                        as={TextField}
                        type="number"
                        margin="normal"
                        variant="standard"
                        fullWidth
                        id="property-cost"
                        label="Property Cost"
                        name="propertycost"
                        autoComplete="propertycost"
                        // helperText={<ErrorMessage name="propertyname" />}
                      />
                    </div>
                    <div className="col-lg-2 col-12">
                      <Field
                        as={TextField}
                        type="number"
                        margin="normal"
                        variant="standard"
                        fullWidth
                        id="property-area"
                        label="Property Area"
                        name="property_area"
                        autoComplete="property_area"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              m<sup>2</sup>
                            </InputAdornment>
                          ),
                        }}
                        // helperText={<ErrorMessage name="propertyname" />}
                      />
                    </div>
                    <div className="col-lg-2 col-12">
                      <Field
                        as={TextField}
                        margin="normal"
                        variant="standard"
                        fullWidth
                        id="property-cost"
                        label="Property Cost"
                        name="propertycost"
                        autoComplete="propertycost"
                        // helperText={<ErrorMessage name="propertyname" />}
                      />
                    </div>
                    <div className="col-lg-2 col-12">
                      <Field
                        as={TextField}
                        type="number"
                        margin="normal"
                        variant="standard"
                        fullWidth
                        id="bedrooms"
                        label="Bed rooms"
                        name="bedrooms"
                        autoComplete="bedrooms"
                        helperText={<ErrorMessage name="bedrooms" />}
                      />
                    </div>
                    <div className="col-lg-2 col-12">
                      <Field
                        as={TextField}
                        type="number"
                        margin="normal"
                        variant="standard"
                        fullWidth
                        id="bathrooms"
                        label="Bath rooms"
                        name="bathrooms"
                        autoComplete="bathrooms"
                        helperText={<ErrorMessage name="bathrooms" />}
                      />
                    </div>
                    <div className="col-lg-2 col-12">
                      <Field
                        as={TextField}
                        type="number"
                        margin="normal"
                        variant="standard"
                        fullWidth
                        id="balconies"
                        label="Nb Of Balcony"
                        name="balconies"
                        autoComplete="balconies"
                        helperText={<ErrorMessage name="balconies" />}
                      />
                    </div>
                    <div className="col-lg-2 col-12">
                      <Field
                        as={TextField}
                        type="number"
                        margin="normal"
                        variant="standard"
                        fullWidth
                        id="parkingslot"
                        label="Nb of Parking Slots"
                        name="parkingslot"
                        autoComplete="parkingslot"
                        helperText={<ErrorMessage name="parkingslot" />}
                      />
                    </div>
                    <div className="col-lg-11 col-12">
                      <Field
                        as={TextField}
                        multiline
                        rows={4}
                        margin="normal"
                        variant="standard"
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        autoComplete="description"
                        helperText={<ErrorMessage name="description" />}
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <h6 className="mb-3">Property Media:</h6>
                    {/* <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {" "}
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )} */}
                    <div className="col-lg-6 col-12">
                      <Field
                        as={TextField}
                        margin="normal"
                        variant="standard"
                        fullWidth
                        id="video_url"
                        label="Video Url"
                        name="video_url"
                        autoComplete="video_url"
                        // helperText={<ErrorMessage name="propertyname" />}
                      />
                    </div>
                  </div>
                  <div className="row my-5">
                    <h6>2. Investment Details section:</h6>
                    <div className="col-12 col-lg-4 px-0">
                      <FormControlLabel
                        sx={{ my: 3 }}
                        label="link this property to an investor"
                        control={
                          <Checkbox
                            checked={checkedLinkToInvestor}
                            onChange={(e) =>
                              setCheckedLinkToInvestor(!checkedLinkToInvestor)
                            }
                          />
                        }
                      />
                    </div>
                    {checkedLinkToInvestor ? (
                      <div className="col-12 col-lg-3 px-0">
                        <FormControl
                          variant="standard"
                          sx={{ mt: 2, p: 0, minWidth: 162 }}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Investor Name
                          </InputLabel>
                          <Field
                            name="investor_username"
                            component={CustomizedSelectForFormik}
                            helperText={
                              <ErrorMessage name="investor_username" />
                            }
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {investorNameList.map((investor) => (
                              <MenuItem value={investor.id}>
                                {investor.username}
                              </MenuItem>
                            ))}
                          </Field>

                          <ErrorMessage name="investor_username">
                            {(msg) => (
                              <div
                                className="font-italic"
                                style={{ color: "#871616", fontSize: "12px" }}
                              >
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </FormControl>
                      </div>
                    ) : null}

                    <div className="col-12 col-lg-2">
                      <FormControl
                        variant="standard"
                        sx={{ mt: 2, p: 0, minWidth: 100 }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Status
                        </InputLabel>
                        <Field
                          name="status"
                          component={CustomizedSelectForFormik}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {statues.map((item) => (
                            <MenuItem
                              value={item.id}
                              disabled={
                                item.title == "Available" &&
                                checkedLinkToInvestor
                                  ? true
                                  : false
                              }
                            >
                              {item.title}
                            </MenuItem>
                          ))}

                          {/* <MenuItem value={"propetiesAZ"}>propetiesAZ</MenuItem> */}
                        </Field>

                        <ErrorMessage name="status">
                          {(msg) => (
                            <div
                              className="font-italic"
                              style={{ color: "#871616", fontSize: "12px" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </FormControl>
                    </div>
                    <div className="col-12 col-lg-2">
                      <FormControl
                        variant="standard"
                        sx={{ mt: 2, p: 0, minWidth: 100 }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Currencies
                        </InputLabel>
                        <Field
                          name="currency"
                          component={CustomizedSelectForFormik}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {currencies.map((item) => (
                            <MenuItem value={item.currency_code}>
                              {item.currency_code}
                            </MenuItem>
                          ))}

                          {/* <MenuItem value={"propetiesAZ"}>propetiesAZ</MenuItem> */}
                        </Field>

                        <ErrorMessage name="currency">
                          {(msg) => (
                            <div
                              className="font-italic"
                              style={{ color: "#871616", fontSize: "12px" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </FormControl>
                    </div>
                    <div className="col-12 col-lg-3">
                      <Field
                        as={TextField}
                        type="number"
                        margin="normal"
                        variant="standard"
                        fullWidth
                        id="priceOfInvestment"
                        label="Amount Of Investment"
                        name="priceOfInvestment"
                        autoComplete="priceOfInvestment"
                        helperText={<ErrorMessage name="priceOfInvestment" />}
                      />
                    </div>
                  </div>
                  <div className="row my-5">
                    <h6>3. Address section:</h6>

                    <div className="col-12 col-lg-3">
                      <Field
                        as={TextField}
                        margin="normal"
                        variant="standard"
                        fullWidth
                        id="region"
                        label="Region"
                        name="region"
                        autoComplete="region"
                        helperText={<ErrorMessage name="region" />}
                      />
                    </div>
                    <div className="col-12 col-lg-4">
                      <Field
                        as={TextField}
                        margin="normal"
                        variant="standard"
                        fullWidth
                        id="street_address"
                        label="Street Address"
                        name="street_address"
                        autoComplete="street_address"
                        helperText={<ErrorMessage name="street_address" />}
                      />
                    </div>
                    <div className="col-12 col-lg-3">
                      <Field
                        as={TextField}
                        margin="normal"
                        variant="standard"
                        fullWidth
                        id="building"
                        label="Building (if applicable)"
                        name="building"
                        autoComplete="building"
                        helperText={<ErrorMessage name="building" />}
                      />
                    </div>

                    <div className="col-12 col-lg-3">
                      <Field
                        as={TextField}
                        margin="normal"
                        variant="standard"
                        fullWidth
                        id="floor"
                        label="Floor"
                        name="floor"
                        autoComplete="floor"
                        helperText={<ErrorMessage name="floor" />}
                      />
                    </div>
                    <div className="col-12 col-lg-3">
                      <Field
                        as={TextField}
                        margin="normal"
                        variant="standard"
                        fullWidth
                        id="state_province"
                        label="State Province"
                        name="state_province"
                        autoComplete="state_province"
                        helperText={<ErrorMessage name="state_province" />}
                      />
                    </div>
                    <div className="col-12 col-lg-3">
                      <Field
                        as={TextField}
                        margin="normal"
                        variant="standard"
                        fullWidth
                        id="postal_code"
                        label="Postal Code"
                        name="postal_code"
                        autoComplete="postal_code"
                        helperText={<ErrorMessage name="postal_code" />}
                      />
                    </div>
                    <div className="d-flex justify-content-between mt-5">
                      <h5> Map Location:</h5>
                      <div>
                        <Button onClick={getCurrentLocation}>
                          <MyLocationIcon /> get Current Location
                        </Button>
                        <Button onClick={() => setLocationInfo(props)}>
                          Set Location
                        </Button>
                      </div>
                    </div>
                    <div
                      className="col-12  rounded p-0"
                      style={{ border: "1px solid #1E90FF" }}
                    >
                      <LoadScript
                        googleMapsApiKey={
                          "AIzaSyD6Tb1IapUrBH4bUXVl778M6WSOo4K_vko"
                        }
                        libraries={libraries}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div />}
                        mapElement={<div />}
                      >
                        <GoogleMap
                          {...defaultMapConfig}
                          center={{
                            lat: currentMarkerLat,
                            lng: currentMarkerLng,
                          }}
                          zoom={20}
                          // onLoad={(map) => onMapLoad(map)}
                        >
                          <Marker
                            position={{
                              lat: currentMarkerLat,
                              lng: currentMarkerLng,
                            }}
                            draggable={true}
                            onDragEnd={(e) =>
                              setMarkerLocation(e.latLng.lng(), e.latLng.lat())
                            }
                          />

                          <StandaloneSearchBox
                            onLoad={(ref) => (inputRef.current = ref)}
                            onPlacesChanged={handlePlaceChanged}
                          >
                            <input
                              type="text"
                              placeholder="City/Town"
                              style={{
                                boxSizing: "border-box",
                                border: `1px solid transparent`,
                                width: `270px`,
                                height: `40px`,
                                padding: `0 12px`,
                                borderRadius: `3px`,
                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                fontSize: `14px`,
                                outline: `none`,
                                margin: "center",
                                textOverflow: `ellipses`,
                                position: "absolute",
                                top: "10px",
                                marginLeft: "70%",
                              }}
                            />
                          </StandaloneSearchBox>
                        </GoogleMap>
                      </LoadScript>
                      {/* <div className="d-flex justify-content-end">
                        <div>
                          <Button onClick={getCurrentLocation}>
                            get Current Location
                          </Button>
                          <Button onClick={() => setLocationInfo(props)}>
                            Set Location
                          </Button>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className=" d-flex justify-content-end">
                    {props.isSubmitting ? (
                      <LoadingButton
                        className="col-lg-3 col-12 mt-4"
                        type="submit"
                        fullWidth
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                      >
                        Save
                      </LoadingButton>
                    ) : (
                      <Button
                        className="col-lg-3 col-12"
                        type="submit"
                        fullWidth
                        // disabled={props.isSubmitting}
                        variant="contained"
                        sx={{
                          mt: 3,
                          mb: 2,
                          ":hover": {
                            bgcolor: "#0f2c4e", // "primary.main" theme.palette.primary.main
                            color: "white",
                          },
                        }}
                      >
                        Save
                      </Button>
                    )}
                  </div>
                  <Snackbar
                    autoHideDuration={6000}
                    variant="soft"
                    color="success"
                    open={openSuccessAlert}
                    onClose={() => setOpenSuccessAlert(false)}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
                    endDecorator={
                      <Button
                        onClick={() => setOpenSuccessAlert(false)}
                        size="sm"
                        variant="soft"
                        color="success"
                      >
                        DIMISS
                      </Button>
                    }
                  >
                    Property Updated successfully.
                  </Snackbar>

                  <Snackbar
                    autoHideDuration={6000}
                    variant="soft"
                    color="danger"
                    open={openErrorAlert}
                    onClose={() => setOpenErrorAlert(false)}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
                    endDecorator={
                      <Button
                        onClick={() => setOpenErrorAlert(false)}
                        size="sm"
                        variant="soft"
                        color="danger"
                      >
                        DIMISS
                      </Button>
                    }
                  >
                    Unsuccessfull Save Property.
                  </Snackbar>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
}

export default EditPropertySection;
