import React, { useState } from "react";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOpenSharpIcon from "@mui/icons-material/MenuOpenSharp";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import DefaultImage from "../adminKit/components/user/assets/user.jpg";
import { CircularProgress } from "@mui/material";
function Navbar({ togleSidebar }) {
  const [file, setFile] = useState(DefaultImage);
  const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(true);

  const [userName, setUserName] = useState(localStorage.getItem("name"));
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/user`, {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
      .then((response) => {
        setIsLoadingUserInfo(false);
        setFile(response.data.data.user.profile_picture);
        setUserName(response.data.data.user.username);
        console.log(response);
        // setLoading(false)

        // setPricingData(response.data.properties)
      })
      .catch((error) => {
        setIsLoadingUserInfo(false);
        console.log(error);
      });

    // Anything in here is fired on component mount.
    document.addEventListener("myEvent", () =>
      setUserName(localStorage.getItem("name"))
    );
    document.addEventListener("myPictureEvent", () =>
      setFile(localStorage.getItem("Picture"))
    );
    return () => {
      // Anything in here is fired on component unmount.
      document.removeEventListener("myEvent", () =>
        setUserName(localStorage.getItem("name"))
      );
      document.removeEventListener("myPictureEvent", () =>
        setFile(localStorage.getItem("Picture"))
      );
    };
  }, []);

  const navigate = useNavigate();
  const logout = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/logout`, {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
      .then((response) => {
        console.log(response);
        localStorage.removeItem("access_token");
        localStorage.removeItem("email");
        localStorage.removeItem("name");
        localStorage.removeItem("loggedIn");

        navigate("/signin");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  let publicUrl = process.env.PUBLIC_URL + "/";

  function handleEvent(event) {
    setUserName({ value: event.detail });
  }

  return (
    <nav className="navbar navbar-expand navbar-light navbar-bg">
      {/*  */}
      {/* onClick={toggleSidebar} */}
      <Link
        className="sidebar-toggle js-sidebar-toggle"
        type="button"
        data-toggle="collapse"
        data-target="#sidebar"
        aria-controls="sidebar"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <MenuOpenSharpIcon />
        {/* <i className="hamburger align-self-center"></i> */}
      </Link>
      <Link className="mx-1" to="/dashboard" style={{ pointer: "cursor" }}>
        dashboard
      </Link>
      <Link className="mx-1" to="/profile" style={{ pointer: "cursor" }}>
        profile
      </Link>

      {isLoadingUserInfo ? (
        ""
      ) : (
        <div className="navbar-collapse collapse">
          <ul
            className="navbar-nav navbar-align"
            style={{ visibility: "visible", opacity: 1 }}
          >
            {/* <li className="nav-item dropdown">
                        <a className="nav-icon dropdown-toggle" href="#" id="alertsDropdown" data-bs-toggle="dropdown">
                            <div className="position-relative">

                                <NotificationsNoneIcon />
                              
                                <span className="indicator">4</span>
                            </div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0"
                            aria-labelledby="alertsDropdown">
                            <div className="dropdown-menu-header">
                                4 New Notifications
                            </div>
                            <div className="list-group">
                                <a href="#" className="list-group-item">
                                    <div className="row g-0 align-items-center">
                                        <div className="col-2">
                                            <i className="text-danger" data-feather="alert-circle"></i>
                                        </div>
                                        <div className="col-10">
                                            <div className="text-dark">Update completed</div>
                                            <div className="text-muted small mt-1">Restart server 12 to complete the
                                                update.</div>
                                            <div className="text-muted small mt-1">30m ago</div>
                                        </div>
                                    </div>
                                </a>
                                <a href="#" className="list-group-item">
                                    <div className="row g-0 align-items-center">
                                        <div className="col-2">
                                            <i className="text-warning" data-feather="bell"></i>
                                        </div>
                                        <div className="col-10">
                                            <div className="text-dark">Lorem ipsum</div>
                                            <div className="text-muted small mt-1">Aliquam ex eros, imperdiet vulputate
                                                hendrerit et.</div>
                                            <div className="text-muted small mt-1">2h ago</div>
                                        </div>
                                    </div>
                                </a>
                                <a href="#" className="list-group-item">
                                    <div className="row g-0 align-items-center">
                                        <div className="col-2">
                                            <i className="text-primary" data-feather="home"></i>
                                        </div>
                                        <div className="col-10">
                                            <div className="text-dark">Login from 192.186.1.8</div>
                                            <div className="text-muted small mt-1">5h ago</div>
                                        </div>
                                    </div>
                                </a>
                                <a href="#" className="list-group-item">
                                    <div className="row g-0 align-items-center">
                                        <div className="col-2">
                                            <i className="text-success" data-feather="user-plus"></i>
                                        </div>
                                        <div className="col-10">
                                            <div className="text-dark">New connection</div>
                                            <div className="text-muted small mt-1">Christina accepted your request.
                                            </div>
                                            <div className="text-muted small mt-1">14h ago</div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="dropdown-menu-footer">
                                <a href="#" className="text-muted">Show all notifications</a>
                            </div>
                        </div>
                    </li> */}

            <li className="nav-item dropdown">
              <a
                className="nav-icon dropdown-toggle d-inline-block d-sm-none"
                href="#"
                data-bs-toggle="dropdown"
              >
                <i className="align-middle" data-feather="settings"></i>
              </a>

              <a
                className="nav-link dropdown-toggle d-none d-sm-inline-block"
                href="#"
                data-bs-toggle="dropdown"
                style={{ paddingRight: 30 }}
              >
                <span className="text-dark mx-1">{userName}</span>
                <img
                  src={file}
                  className="avatar rounded-circle img-fluid rounded me-1"
                  alt="Charles Hall"
                />
              </a>
              <div className="dropdown-menu dropdown-menu-end">
                <Link className="dropdown-item" to="/profile">
                  <PermIdentityIcon style={{ color: "gray" }} /> Profile
                </Link>
                <a className="dropdown-item" href="#">
                  <PieChartOutlineIcon style={{ color: "gray" }} /> Analytics
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="index.html">
                  <SettingsOutlinedIcon style={{ color: "gray" }} /> Settings &
                  Privacy
                </a>
                <a className="dropdown-item" href="#">
                  <HelpOutlineOutlinedIcon style={{ color: "gray" }} /> Help
                  Center
                </a>
                <div className="dropdown-divider"></div>
                <button
                  className="dropdown-item"
                  onClick={logout}
                  role="button"
                >
                  Log out
                </button>
              </div>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
